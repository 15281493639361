import { WrapBuilderPage } from '../_constants/enums/wrap-builder-pages.enum'
import { Action } from '../_types/action.type'
import ActionTypes from './action-types'

const initialState = {
  unsavedChanges: false,
  wrapBuilderPage: WrapBuilderPage.meta,
  showToast: false
}

const Reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user
      }
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        errMsg: action.payload.errMsg
      }
    case ActionTypes.SET_CURRENT_CONTENT:
      return {
        ...state,
        currentContent: action.payload.content
      }
    case ActionTypes.SET_STATUS_BAR:
      return {
        ...state,
        statusMsg: action.payload.statusMsg
      }
    case ActionTypes.SET_CURRENT_WRAP:
      return {
        ...state,
        currentWrap: action.payload.wrap,
        unsavedChanges: false
      }

    case ActionTypes.SET_BUILDER_PAGE:
      return {
        ...state,
        wrapBuilderPage: action.payload.wrapBuilderPage
      }
    case ActionTypes.SET_TOAST:
      return {
        ...state,
        showToast: true,
        toastHeader: action.payload.toastHeader,
        toastMsg: action.payload.toastMsg
      }
    case ActionTypes.CLEAR_TOAST:
      return {
        ...state,
        showToast: false
      }
    case ActionTypes.SET_UNSAVED_CHANGES:
      return {
        ...state,
        unsavedChanges: action.payload.unsavedChanges
      }
    default:
      return state
  }
}

export default Reducer
