import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/wrap`,
  withCredentials: false
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class WrapService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async getPresignedURL (
    prefix: string,
    name: string
  ): Promise<
    | { url: string; key: string }
    | { errMSg: string; url?: string; key?: string }
  > {
    return axiosInstance
      .post('/presign', {
        prefix,
        name
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async createBlank (tag: string, formattedDate: string) {
    return axiosInstance
      .post('/blank', {
        tag,
        formattedDate
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async cloneWrap (id: string) {
    return axiosInstance
      .post('/clone', {
        id
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async deleteWrapById (id: string) {
    return axiosInstance
      .post('/delete', {
        id
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async updateWrap (id: string, fields: any) {
    console.log(`GETTING CALLED`)
    return axiosInstance
      .put(`/update/${id}`, { fields })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async getById (id: string) {
    return axiosInstance
      .get(`/id/${id}`)
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async getPreviousWrap (id: string) {
    return axiosInstance
      .get(`/previous/${id}`)
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async queryWraps (page: number) {
    return axiosInstance
      .get(`/query?page=${page}`)
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
