import { NavLink } from 'react-router-dom'
import {
  AiOutlineHome,
  AiOutlineCalendar,
  AiOutlineMobile,
  AiOutlineBell,
  AiOutlineUsergroupAdd,
  AiOutlineFire,
  AiOutlineClockCircle,
  AiOutlineFileProtect,
  AiOutlineAlert,
  AiOutlineDollar,
  AiOutlineComment,
  AiOutlineUser
} from 'react-icons/ai'
import { useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Button } from 'react-bootstrap'
import { FaFire, FaHourglass, FaRegHourglass } from 'react-icons/fa'

export default function SideBarNav() {
  const [collapsed, setCollapsed] = useState(true)

  const _logout = () => {
    localStorage.removeItem('carnivore-token')
    window.location.reload()
  }
  return (
    <Sidebar
      collapsed={collapsed}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      <div className='vh-100 flex-column d-flex  justify-content-between py-3'>
        <div>
          <div className='text-center'>
            <img
              src={require('../assets/logo.png')}
              alt='carnivore-logo'
              className='my-3'
              width={'40px'}
            />
          </div>
          <Menu
            menuItemStyles={{
              icon: {
                fontSize: '1.4rem'
              },
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: '#efefef',
                  fontWeight: 'bold'
                },
                fontSize: '1.1rem'
              }
            }}
          >
            <MenuItem
              className='step-0'
              component={<NavLink to='/' />}
              icon={<AiOutlineHome />}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              className='step-0'
              component={<NavLink to='/users' />}
              icon={<AiOutlineUser />}
            >
              Users
            </MenuItem>
            {/* <MenuItem
              className='step-0'
              component={<NavLink to='/send-alerts' />}
              icon={<AiOutlineBell />}
            >
              Send Alerts
            </MenuItem> */}
            {/* <MenuItem
              className='step-0'
              component={<NavLink to='/daily-wrap' />}
              icon={<AiOutlineCalendar />}
            >
              Daily Wrap
            </MenuItem> */}
            <MenuItem
              className='step-0'
              component={<NavLink to='/sector-heaters' />}
              icon={<AiOutlineFire />}
            >
              Sector Heaters
            </MenuItem>
            {/* <MenuItem
              className='step-0'
              component={<NavLink to='/content' />}
              icon={<AiOutlineFileProtect />}
            >
              Premium Content
            </MenuItem> */}
            <MenuItem
              className='step-0'
              component={<NavLink to='/buy-hold' />}
              icon={<AiOutlineClockCircle />}
            >
              Buy & Hold
            </MenuItem>
            <MenuItem
              className='step-0'
              component={<NavLink to='/events' />}
              icon={<AiOutlineCalendar />}
            >
              Events
            </MenuItem>
            <MenuItem
              className='step-0'
              component={<NavLink to='/banner' />}
              icon={<AiOutlineAlert />}
            >
              Banner
            </MenuItem>
            <MenuItem
              className='step-0'
              component={<NavLink to='/pricing' />}
              icon={<AiOutlineDollar />}
            >
              Pricing
            </MenuItem>

            <MenuItem
              className='step-0'
              component={<NavLink to='/feedback' />}
              icon={<AiOutlineComment />}
            >
              User Feedback
            </MenuItem>
            <MenuItem
              className='step-0'
              component={<NavLink to='/team' />}
              icon={<AiOutlineUsergroupAdd />}
            >
              Teammates
            </MenuItem>
          </Menu>
        </div>
        <div className='p-2'>
          <Button variant='link' onClick={_logout}>
            Logout
          </Button>
        </div>
      </div>
    </Sidebar>
  )
}
