import { useDispatch, useSelector } from 'react-redux'
import { WrapBuilderProps } from '../../../_types/wrap-builder-props.interface'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { FormEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { WrapService } from '../../../services/wrap.service'
import Actions from '../../../redux/actions'
import { QueryHelper } from '../../../helpers/queryparams.helper'

export default function WrapMetadata () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [formattedDate, setFormattedDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [tag, setTag] = useState('')

  const location = useLocation()

  const dispatch = useDispatch()
  const wrapService = new WrapService(dispatch)

  useEffect(() => {
    if (currentWrap) {
      setFormattedDate(currentWrap.formattedDate ?? '')
      setTag(currentWrap.tag ?? '')
    }
  }, [currentWrap])

  const _save = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    let response
    if (!QueryHelper.get('id')) {
      //update
      response = await wrapService.createBlank(tag, formattedDate)
      QueryHelper.updateQueryParam('id', response.wrap.id)
      
    } else {
      //create New
      response = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
        formattedDate,
        tag
      })
    }
    if (response.wrap) {
     
      dispatch(Actions.setCurrentWrap(response.wrap))
    }
    dispatch(
      Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
    )
  }

  return (
    <Container className='p-3'>
      <h4 className='text-start'>Metadata</h4>
      <hr />
      <Row className='justify-content-center'>
        <Col md={6}>
          <div className='p-3 rounded border shadow text-start'>
            <Form onSubmit={_save}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  required
                  value={formattedDate}
                  onChange={e => {
                    console.log(e.target.value)
                    setFormattedDate(e.target.value)
                  }}
                  type='date'
                />
              </Form.Group>

              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Tag</Form.Label>
                <Form.Select
                  required
                  value={tag}
                  onChange={e => setTag(e.target.value)}
                >
                  <option value={''}>Select pro/basic</option>
                  <option value={'pro'}>PRO</option>
                  <option value={'basic'}>BASIC</option>
                </Form.Select>
              </Form.Group>

              <div className='text-center'>
                <Button type='submit'>Submit</Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
