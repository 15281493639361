import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { SectorHeater, Stock } from '../../_types/sector-heaters.type'
import { useEffect, useState } from 'react'
import RichTextEditor from '../rich-text-editor'
import { FaDollarSign } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { HeatersService } from '../../services/heaters.service'
import Actions from '../../redux/actions'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { Alert } from '@mui/material'

interface CreateHeaterModalProps extends CarnivoreModalProps {
  heater: SectorHeater | null
  onRefresh: () => Promise<void>
}

export default function CreateHeaterModal(props: CreateHeaterModalProps) {
  const [sectorHeater, setSectorHeater] = useState<SectorHeater>(
    props.heater ?? {
      title: '',
      isExpired: false,
      commentary: '',
      stocks: []
    }
  )
  const [flicker, setFlicker] = useState(true)
  const [content, setContent] = useState(props.heater?.commentary ?? '')

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const error = useSelector((state: CarnivoreState) => state.errMsg)
  const heatersService = new HeatersService(dispatch)

  useEffect(() => {
    if (props.heater) {
      const clone: SectorHeater = JSON.parse(JSON.stringify(sectorHeater))
      for (const stock of clone.stocks) {
        stock.entryDate = new Date(stock.entryDate).toISOString().slice(0, 10)
      }
      setSectorHeater(clone)
    }
  }, [])
  const addStock = () => {
    const newStock: Stock = {
      symbol: '',
      name: '',
      shares: 0,
      isSold: false,
      entryDate: '',
      entryPX: 0
    }
    setSectorHeater(prevState => ({
      ...prevState,
      stocks: [...prevState.stocks, newStock]
    }))
  }

  const handleStockChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedStocks = [...sectorHeater.stocks]
    updatedStocks[index] = {
      ...updatedStocks[index],
      [e.target.name]: e.target.value
    }
    setSectorHeater(prevState => ({ ...prevState, stocks: updatedStocks }))
  }

  const removeStock = (index: number) => {
    const updatedStocks = [...sectorHeater.stocks]
    updatedStocks.splice(index, 1)
    setSectorHeater(prevState => ({ ...prevState, stocks: updatedStocks }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!content) {
      alert('Commentary Is required. Let Carnivores know about why you picked this sector')
      return;
    }

    if (!sectorHeater.title) {
      alert('Title is required')
      return;
    }

    for (const stock of sectorHeater.stocks) {
      if (!stock.name || !stock.symbol || !stock.entryDate || !stock.entryPX) {
        alert(`Please fill out all stock information. ${stock.name === '' ? 'You have a blank row' : `For Stock ${stock.name}`}`)
        return;
      }
    }

    // Submit your form data...
    console.log(sectorHeater)

    if (sectorHeater.stocks?.length === 0) {
      alert('Please add at least one stock')
      return
    }
    setLoading(true)
    const clone: SectorHeater = JSON.parse(JSON.stringify(sectorHeater))
    clone.commentary = content
    for (const stock of clone.stocks) {
      stock.symbol = stock.symbol.toUpperCase().trim()
    }
    let res
    if (sectorHeater.id) {
      res = await heatersService.updateHeater(clone)
    } else {
      res = await heatersService.createHeater(clone)
    }
    if (!res.heater) {
      alert('an error occurred')
    }
    setLoading(false)

    if (res.heater) {
      dispatch(
        Actions.setToast('Sector Heater Created', 'This sector heater is live')
      )
      await props.onRefresh()
      props.onClose()
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = e.target as any
    setSectorHeater(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  return (
    <Modal show={props.show} size='xl' centered onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.heater ? 'Edit' : 'Create'} Sector Heater
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className='align-items-center'>
            <Col md={10}>
              <Form.Group controlId='formTitle'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  name='title'
                  required
                  placeholder='Example: Industrial Heaters'
                  value={sectorHeater.title}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={2}></Col>
          </Row>

          <Form.Group controlId='commentary' className='mt-4'>
            <Form.Label>Commentary</Form.Label>

            {flicker && (
              <RichTextEditor
                initialValue={props.heater?.commentary ?? ''}
                onHTMLChange={e => {
                  setContent(e)
                }}
              />
            )}
          </Form.Group>
          <Form.Label className='mt-3'>Stocks</Form.Label>
          {sectorHeater.stocks.map((stock, index) => (
            <Row
              key={index}
              className='mt-3 pb-3 align-items-end p-1 border-bottom'
            >
              <Col md={1}>
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Symbol'
                  name='symbol'
                  value={stock.symbol}
                  onChange={e => handleStockChange(index, e as any)}
                />
              </Col>
              <Col md={2}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  name='name'
                  value={stock.name}
                  onChange={e => handleStockChange(index, e as any)}
                />
              </Col>
              <Col md={2}>
                <Form.Label>Entry Date</Form.Label>

                <Form.Control
                  type='date'
                  placeholder='Entry Date'
                  name='entryDate'
                  value={stock.entryDate}
                  onChange={e => handleStockChange(index, e as any)}
                />
              </Col>
              <Col md={2} className='d-flex flex-column align-items-flex-end justify-content-flex-end'>
                <Form.Label>Entry Price</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='rounded-start'>
                    <FaDollarSign />
                  </InputGroup.Text>
                  <Form.Control
                    type='number'
                    placeholder='Entry PX'
                    name='entryPX'
                    value={stock.entryPX}
                    onChange={e => handleStockChange(index, e as any)}
                  />
                </InputGroup>
              </Col>
              <Col md={2} className='d-flex flex-column align-items-flex-end justify-content-flex-end'>
                <Form.Label>Shares</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='number'
                    placeholder='Initial Shares'
                    name='shares'
                    value={stock.shares ?? 0}
                    onChange={e => handleStockChange(index, e as any)}
                  />
                </InputGroup>
              </Col>
              {/* Additional fields for each stock... */}
              <Col>
                <Button variant='danger' onClick={() => removeStock(index)}>
                  Remove
                </Button>
              </Col>
            </Row>
          ))}
          {error && <Alert color='error'>{error}</Alert>}
          <div className='my-4'>
            <br />
            <Button onClick={addStock}>Add Stock</Button>
          </div>
          <br />
          <hr />
          <div className='text-end '>
            <Button variant='link me-2' onClick={props.onClose}>
              Close
            </Button>
            <Button disabled={loading} variant='success' type='submit'>
              {props.heater ? 'Save Changes' : 'Create Heater'}
              {loading && (
                <Spinner animation='border' size='sm' className='ms-1' />
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
