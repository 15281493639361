import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'
import { LongTerm } from '../_types/longterm.type'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/settings`,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class SettingsService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async getSettings () {
    return axiosInstance
      .get('/', {
        params: {}
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
  async getPrice (priceId: string) {
    return axiosInstance
      .get('/price', {
        params: { price: priceId }
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async updatePrice (amount: number, interval : "month" | "year") {
    return axiosInstance
      .put('/price', {
        amount,
        interval
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
