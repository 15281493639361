import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { Button } from 'react-bootstrap'
import Actions from '../../redux/actions'

export default function WrapDiscardBtn () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)
  const unsavedChanges = useSelector(
    (state: CarnivoreState) => state.unsavedChanges
  )

  const dispatch = useDispatch()

  const _discard = () => {
    if (
      window.confirm(
        'All Changes to this section will be lost. Are you sure you want to discard'
      )
    ) {
      dispatch(Actions.setUnsavedChanged(false))
      dispatch(Actions.setCurrentWrap(JSON.parse(JSON.stringify(currentWrap))))
    }
  }
  return (
    <>
      {unsavedChanges ? (
        <Button variant='danger me-2' onClick={_discard}>
          Discard Changes
        </Button>
      ) : (
        <></>
      )}
    </>
  )
}
