import { useEffect, useState } from 'react'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { CarnivoreEvent } from '../../_types/carnivore-event.type'
import { Button, Col, Form, Modal, ModalHeader, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { EventService } from '../../services/event.service'
import DragFileInput from '../drag-file-input'
import CarnivoreFileInput from '../file-input'
import { Formatters } from '../../helpers/formatters'
import Actions from '../../redux/actions'

interface EventModalProps extends CarnivoreModalProps {
  initialEvent: CarnivoreEvent | null
  onUpdate?: (ev: CarnivoreEvent, action: string) => void
}
export default function EventModal (props: EventModalProps) {
  const [loading, setLoading] = useState(false)

  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const [formData, setFormData] = useState<any>({
    title: '',
    shortDescription: '',
    longDescription: '',
    startTime: '',
    endTime: '',
    imgID: '',
    link: '',
    linkTxt: '',
    package : 'all',
    ...props.initialEvent
  })

  const dispatch = useDispatch()
  const eventService = new EventService(dispatch)

  useEffect(() => {
    console.log(`TZ: ${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
  }, [])

  useEffect(() => {
    if (props.initialEvent) {
      const ev = props.initialEvent

      console.log(`START: ${Formatters.formatDateTimeInput(ev.startTime)}`)
      setFormData({
        title: ev.title,
        shortDescription: ev.shortDescription,
        longDescription: ev.longDescription,
        startTime: Formatters.isoStringToLocalTimeInput(ev.startTime),
        endTime: Formatters.isoStringToLocalTimeInput(ev.endTime),
        imgID: ev.imgID ?? '',
        link: ev.link ?? '',
        linkTxt: ev.linkTxt ?? '',
        package: ev.package,
        eventType: ev.eventType,
        recordedLink: ev.recordedLink ?? ''
      })
    }else{
      setFormData({
        title: '',
        shortDescription: '',
        longDescription: '',
        startTime: '',
        endTime: '',
        imgID: '',
        package : 'all',
        link: '',
        linkTxt: ''
      })
    }
  }, [props.initialEvent])
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type, checked } = e.target as any
    console.log(value)

    setFormData((prev : any) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    if (imgSrc) {
      formData.imgID = imgSrc
    }
    if (props.initialEvent) {
      console.log(formData)
      const res = await eventService.updateEvent(
        props.initialEvent.id,
        formData,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      dispatch(
        Actions.setToast('Event Updated', 'Your changes are live immediately')
      )
      if (props.onUpdate) {
        props.onUpdate(res.event, 'update')
      }
      props.onClose()
    } else {
      const res = await eventService.createEvent(
        formData,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      if (props.onUpdate) {
        props.onUpdate(res.event, 'create')
      }
      dispatch(
        Actions.setToast('Event Created', 'Your changes are live immediately')
      )
      props.onClose()
    }
    setFormData({
      title: '',
      shortDescription: '',
      longDescription: '',
      startTime: '',
      endTime: '',
      imgID: '',
      link: '',
      package : 'all',
      linkTxt: ''
    })
  }

  return (
    <Modal show={props.show} size='lg' centered onHide={props.onClose}>
      <ModalHeader closeButton>
        <Modal.Title>
          {props.initialEvent ? 'Edit Event' : 'Create Event'}
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              name='title'
              required
              value={formData.title}
              onChange={handleChange}
              placeholder='Title'
            />
          </Form.Group>
          <CarnivoreFileInput
            initialKey={imgSrc ?? ''}
            prefix='events'
            label='Event Image (Optional)'
            inputName='event-img'
            onFileUploaded={(src: string | null) => setImgSrc(src)}
          />

          <Row>
            {/* <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Package</Form.Label>
                <Form.Select
                  name='package'
                  required
                  value={formData.package}
                  onChange={handleChange}
                >
                  <option value=''>Select Package</option>
                  <option value='all'>All Carnivores</option>
                  <option value='pro'>Pro</option>
                  <option value='basic'>Basic</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Event Type</Form.Label>
                <Form.Select
                  name='eventType'
                  required
                  value={formData.eventType}
                  onChange={handleChange}
                >
                  <option value=''>Select Type</option>
                  <option value='general'>General</option>
                  <option value='news'>News Appearance</option>
                  <option value='member-call'>Member Call</option>
                  <option value='academy'>Academy</option>
                  <option value='virtual-academy'>Virtual Academy</option>
                  <option value='livestream'>Live Stream</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              type='text'
              name='shortDescription'
              required
              value={formData.shortDescription}
              onChange={handleChange}
              placeholder='Short Description'
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Long Description</Form.Label>
            <Form.Control
              as={'textarea'}
              name='longDescription'
              required
              rows={5}
              value={formData.longDescription}
              onChange={handleChange}
              placeholder='Long Description'
            />
          </Form.Group>
          <Row>
            <Col md={8}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Link <em>(Optional)</em>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='link'
                  value={formData.link}
                  onChange={handleChange}
                  placeholder='https://'
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Link Button Label <em>(Optional)</em>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='linkTxt'
                  value={formData.linkTxt}
                  onChange={handleChange}
                  placeholder='Join Call'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type='datetime-local'
                  name='startTime'
                  required
                  step={'300'}
                  value={formData.startTime}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type='datetime-local'
                  name='endTime'
                  required
                  step={'300'}
                  value={formData.endTime}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {formData.eventType === 'member-call' && (
            <Form.Group className='mb-3'>
              <Form.Label>Recording Link</Form.Label>
              <Form.Control
                type='text'
                name='recordedLink'
                value={formData.recordedLink}
                onChange={handleChange}
                placeholder='Call Recording Link'
              />
              <Form.Text>
                After a member call is finished, update this field with a link
                to the call recording.
              </Form.Text>
            </Form.Group>
          )}

          <p className='text-secondary'>
            <small>
              Times shown in your local timezone for{' '}
              {Intl.DateTimeFormat().resolvedOptions().timeZone} and will adjust
              to the user's timezone
            </small>
          </p>
          <div className='text-center'>
            <Button type='submit'>Save Event</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
