import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import Login from './pages/Login'
import ErrorBar from './components/error-bar'
import { Route, Routes, useNavigate, redirect } from 'react-router-dom'
import {
  Col,
  Container,
  Row,
  Spinner,
  Toast,
  ToastContainer
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from './_types/carnivore-state.type'
import { ConsoleUserService } from './services/consoleuser.service'
import Actions from './redux/actions'
import SideBarNav from './components/side-navbar'
import Dashboard from './pages/Dashboard'
import AppUsers from './pages/AppUsers'
import Team from './pages/Team'
import DailyWrapBuilder from './pages/wrap/DailyWrapBuilder'
import DailyWrapPage from './pages/wrap/DailyWrap'
import DailyWrapPreview from './pages/wrap/DailyWrapPreview'
import StatusBar from './components/status-bar'
import ChangePassModal from './components/modal/changepass-modal'
import SectorHeaters from './pages/SectorHeaters'
import EventsPage from './pages/Events'
import LongTerm from './pages/LongTerm'
import PremiumContent from './pages/PremiumContent'
import CreateContent from './pages/CreateContent'
import AlertsPage from './pages/SendAlerts'
import BannerPage from './pages/Banner'
import PricingPage from './pages/Pricing'
import FeedbackPage from './pages/Feedback'
import UsersPage from './pages/Users'

function App () {
  const [initialLoad, setInitialLoad] = useState(true)
  const user = useSelector((state: CarnivoreState) => state.user)
  const showToast = useSelector((state: CarnivoreState) => state.showToast)
  const toastMsg = useSelector((state: CarnivoreState) => state.toastMsg)
  const toastHeader = useSelector((state: CarnivoreState) => state.toastHeader)

  const [showPwChange, setShowPwChange] = useState(false)
  const dispatch = useDispatch()
  const consoleUserService = new ConsoleUserService(dispatch)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      _checkForUser()
    }, 1000)
  }, [])

  useEffect(() => {
    if (user) {
      if (user.requirePasswordReset) {
        setShowPwChange(true)
      }
    }
  }, [user])

  const _checkForUser = async () => {
    setInitialLoad(false)
    const token = localStorage.getItem('carnivore-token')
    if (token) {
      const res = await consoleUserService.getConsoleUser()
      if (!res.user) {
        localStorage.removeItem('carnivore-token')
        setInitialLoad(false)
        dispatch(Actions.setError(`Your Session Has Expired`))
        navigate('/login?expired=true', { replace: true })
      } else {
        setInitialLoad(false)
        dispatch(Actions.setUser(res.user))
        if (window.location.href.includes('/login')) {
          navigate('/')
        }
      }
    } else {
      setInitialLoad(false)
      if (!window.location.href.includes('/login')) {
        navigate('/login', { replace: true })
      }
    }
  }
  return (
    <div className='App' style={{ display: 'flex', height: '100vh' }}>
      <ErrorBar />
      <StatusBar />
      {initialLoad ? (
        <>
          <Container fluid className='vh-100 bg-white'>
            <Row className='h-100 align-items-center'>
              <Col>
                <Row>
                  <Col md={2} className='mx-auto mb-3'>
                    <img
                      className='img-fluid'
                      src={require('./assets/logo.png')}
                      alt='logo'
                    />
                  </Col>
                </Row>
                <Spinner animation='border' variant='primary' />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          {user &&
            !window.location.href.includes('signup') &&
            !window.location.href.includes('login') && <SideBarNav />}

          <section className='w-100 vh-100 ' style={{ overflowY: 'scroll' }}>
            <Col>
              <Router />
            </Col>
          </section>

          <ToastContainer position='top-end' className='p-3 z-99 '>
            <Toast
              onClose={() => dispatch(Actions.clearToast())}
              show={showToast}
              className='bg-light'
              delay={4000}
              autohide
            >
              <Toast.Header className='bg-light'>
                <strong className='me-auto'>{toastHeader}</strong>
              </Toast.Header>
              <Toast.Body>{toastMsg}</Toast.Body>
            </Toast>
          </ToastContainer>
        </>
      )}
      <ChangePassModal
        show={showPwChange}
        onClose={() => setShowPwChange(false)}
      />
    </div>
  )
}

function Router () {
  return (
    <Routes>
      <Route path='/login' element={<Login title='Login' />} />
      <Route path='/' element={<Dashboard title='Dashboard' />} />
      <Route
        path='/daily-wrap'
        element={<DailyWrapPage title='Daily Wrap' />}
      />
      <Route
        path='/daily-wrap/builder'
        element={<DailyWrapBuilder title='Wrap Editor' />}
      />
      <Route
        path='/daily-wrap/preview'
        element={<DailyWrapPreview title='Preview Wrap' />}
      />
      <Route
        path='/sector-heaters'
        element={<SectorHeaters title='Sector Heaters' />}
      />
      <Route path='/mobile-users' element={<AppUsers title='Mobile Users' />} />
      <Route path='/team' element={<Team title='Team' />} />
      <Route path='/events' element={<EventsPage title='Events' />} />
      <Route path='/buy-hold' element={<LongTerm title='Events' />} />
      <Route path='/content' element={<PremiumContent title='Premium Content' />} />
      <Route path='/banner' element={<BannerPage title='Banner' />} />
      <Route path='/feedback' element={<FeedbackPage title='User Feedback' />} />
      <Route path='/users' element={<UsersPage title='Users' />} />

      <Route path='/content/create' element={<CreateContent title='Create Content' />} />
      <Route path='/pricing' element={<PricingPage title='Pricing' />} />

      <Route path='/send-alerts' element={<AlertsPage title='Send alert' />} />
    </Routes>
  )
}

export default App
