import { Container } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { useEffect, useState } from 'react'
import EventModal from '../components/modal/event-modal'
import { useDispatch } from 'react-redux'
import { CarnivoreEvent } from '../_types/carnivore-event.type'
import { QueryHelper } from '../helpers/queryparams.helper'
import { EventService } from '../services/event.service'
import useQueryParamsV2 from '../hooks/userQueryParamsV2'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { StyleHelper } from '../helpers/styler-helper'
import { Formatters } from '../helpers/formatters'
import {
  MdCalendarMonth,
  MdCampaign,
  MdLiveTv,
  MdSchool,
  MdVideoCall,
  MdVideoCameraFront
} from 'react-icons/md'
import {
  CalendarMonth,
  Campaign,
  Close,
  Delete,
  Edit,
  FilterAlt,
  LiveTv,
  School,
  VideoCall,
  Videocam
} from '@mui/icons-material'
import LoadingButtonMUI from '../components/loading-btn copy'

interface EventsPageQueryParms {
  startDate?: string
  endDate?: string
  eventType?: string
}

export default function EventsPage (props: PageProps) {
  useDocumentTitle(props.title)
  const [queryParams, setQueryParams] = useQueryParamsV2<EventsPageQueryParms>()

  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [refreshing, setRefreshing] = useState(true)
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<CarnivoreEvent[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalDocs, setTotalDocs] = useState(0)
  const [eventType, setEventType] = useState(queryParams.eventType ?? '')
  const [editingEvent, setEditingEvent] = useState<CarnivoreEvent | null>(null)
  const [startDate, setStartDate] = useState<string>(
    queryParams.startDate ?? formatDate(addMonths(new Date(), -1))
  )
  const [endDate, setEndDate] = useState<string>(
    queryParams.endDate ?? formatDate(addMonths(new Date(), 1))
  )

  const dispatch = useDispatch()
  const eventService = new EventService(dispatch)

  useEffect(() => {
    _fetchEvents(1, startDate, endDate, eventType)
  }, [])

  function addMonths (date: Date, months: number): Date {
    const result = new Date(date)
    result.setMonth(result.getMonth() + months)
    return result
  }

  const _clearSearch = () => {
    const s = new Date()
    setStartDate(formatDate(addMonths(s, -1)))
    setEndDate(formatDate(addMonths(new Date(), 1)))
    setEventType('')
    _fetchEvents(
      1,
      formatDate(addMonths(s, -1)),
      formatDate(addMonths(new Date(), 1)),
      ''
    )
  }

  const _setToMidnight = (d: Date) => {
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
  }

  const _delete = async (id: string) => {
    if (
      window.confirm(
        'Are you sure you want to delete this event. This action cannot be undone'
      )
    ) {
      await eventService.deleteEvent(id)
      setEvents(events.filter(e => e.id !== id))
    }
  }

  const _fetchEvents = async (
    p: number,
    startD: string,
    endD: string,
    eventT: string
  ) => {
    setPage(p)
    const start = new Date(startD)
    const end = new Date(endD)
    _setToMidnight(start)
    _setToMidnight(end)

    setQueryParams({
      endDate: endD,
      startDate: startD,
      eventType: eventT
    })

    const results = await eventService.searchEvents(
      p,
      start.toISOString(),
      end.toISOString(),
      eventT
    )
    if (results.docs) {
      setEvents(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
      setTotalDocs(results.totalDocs)
    }
    setRefreshing(false)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  function formatDate (date: Date): string {
    return date.toISOString().split('T')[0]
  }

  const _getIcon = (event: CarnivoreEvent) => {
    switch (event.eventType) {
      case 'news':
        return <Campaign />
      case 'member-call':
        return <VideoCall />
      case 'academy':
        return <School />
      case 'virtual-academy':
        return <Videocam />
      case 'livestream':
        return <LiveTv />
      default:
        return <CalendarMonth />
    }
  }

  const _onUpdate = (event: CarnivoreEvent, action: string) => {
    if (action === 'update') {
      const currentEvents: CarnivoreEvent[] = JSON.parse(JSON.stringify(events))

      const index = currentEvents.findIndex(e => e.id === event.id)
      if (index >= 0) {
        currentEvents[index] = event
      }
      setEvents(currentEvents)
    } else if (action === 'create') {
      setEvents([...events, event])
    }
  }

  return (
    <Container className='p-3'>
      <h1 className='text-start'>Events</h1>
      <p className='text-start'>
        Create, edit and delete upcoming events like member calls, news
        appearances or live streams
      </p>
      <Divider />
      <Paper elevation={3}>
        <Box p={2} sx={{ textAlign: 'start' }}>
          <StyleHelper.VerticalSpace />
          <Box
            justifyContent={'space-between'}
            alignItems={'center'}
            display={'flex'}
          >
            <Typography variant='subtitle1' fontWeight={'bold'}>
              Filters
            </Typography>
            <Button
              variant='contained'
              color='success'
              onClick={() => setShowModal(true)}
            >
              Create Event
            </Button>
          </Box>
          <StyleHelper.VerticalSpace />

          <Grid container spacing={2} alignItems='center'>
            <Grid item lg={3}>
              <TextField
                label='Start Date'
                type='date'
                fullWidth
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                fullWidth
                label='End Date'
                type='date'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <FormControl fullWidth>
                <InputLabel id='event-type-label'>Event Type</InputLabel>
                <Select
                  labelId='event-type-label'
                  id='event-type-select'
                  value={eventType}
                  label='Event Type'
                  onChange={e => setEventType(e.target.value)}
                >
                  <MenuItem value=''>All Events</MenuItem>

                  <MenuItem value='general'>General</MenuItem>
                  <MenuItem value='news'>News</MenuItem>
                  <MenuItem value='member-call'>Member Calls</MenuItem>
                  <MenuItem value='academy'>Academy</MenuItem>
                  <MenuItem value='virtual-academy'>Virtual Academy</MenuItem>
                  <MenuItem value='livestream'>Livestream</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3}>
              <LoadingButtonMUI
                variant='outlined'
                endIcon={<FilterAlt />}
                onClick={() => _fetchEvents(1, startDate, endDate, eventType)}
                loading={loading}
                disabled={loading}
              >
                Apply Filter
              </LoadingButtonMUI>
              <IconButton onClick={_clearSearch} disabled={loading}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />
        </Box>
      </Paper>

      <StyleHelper.VerticalSpace />
      {!loading && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='right'>Type</TableCell>
                  <TableCell align='right'>Start Date</TableCell>
                  <TableCell align='right'>End Date</TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events?.map(e => (
                  <TableRow
                    key={e.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f2f2f2'
                      }
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {e.title}
                    </TableCell>
                    <TableCell align='right'>
                      <Chip
                        label={Formatters.removeHyphensAndUppercase(
                          e.eventType
                        )}
                        icon={_getIcon(e)}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formateLocalTZ(e.startTime)}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formateLocalTZ(e.endTime)}
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        aria-label='edit'
                        title='Edit Event'
                        onClick={() => {
                          setEditingEvent(e)
                          setShowModal(true)
                        }}
                      >
                        <Edit color='info' />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        title='Delete Event'
                        onClick={() => _delete(e.id)}
                      >
                        <Delete color='error' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyleHelper.VerticalSpace />
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event: unknown, page: number) => {
                _fetchEvents(page, startDate, endDate, eventType)
              }}
              variant='outlined'
              shape='rounded'
            />
          </Stack>
        </>
      )}

      <EventModal
        onUpdate={_onUpdate}
        initialEvent={editingEvent}
        show={showModal}
        onClose={() => {
          setShowModal(false)
          setEditingEvent(null)
        }}
      />
    </Container>
  )
}
