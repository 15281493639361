import {
  Col,
  Container,
  ListGroup,
  Row,
  Button,
  Spinner
} from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../_types/carnivore-state.type'
import { useEffect, useState } from 'react'
import ContentMeta from '../components/content/content.meta'
import { CarnivoreContent } from '../_types/carnivore-content.type'
import Actions from '../redux/actions'
import ContentUpload from '../components/content/content.upload'
import ContentDescription from '../components/content/content.description'
import { ContentService } from '../services/content.service'
import useQueryParamsV2 from '../hooks/userQueryParamsV2'

interface ContentQueryParams {
  id?: string
}

export default function CreateContent (props: PageProps) {
  useDocumentTitle(props.title)
  const [queryParams, setQueryParams] = useQueryParamsV2<ContentQueryParams>()

  const currentContent = useSelector(
    (state: CarnivoreState) => state.currentContent
  )

  const [isLoading, setIsLoading] = useState(true)
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()
  const contentService = new ContentService(dispatch)

  useEffect(() => {
    _initialize()
  }, [])
  const _next = () => {
    if (step < 4) {
      setStep(prev => prev + 1)
    }
  }

  const _setCurrentContent = (content: CarnivoreContent) => {
    dispatch(Actions.setCurrentContent(content))
  }

  const _initialize = async () => {
    setIsLoading(true)

    if (queryParams.id) {
      const res = await contentService.getById(queryParams.id ?? '')
      if (res.content) {
        dispatch(Actions.setCurrentContent(res.content))
      } else {
        setQueryParams({ id: undefined })
      }
    }
    setIsLoading(false)
  }

  return (
    <Container className='p-3'>
      <h1 className='text-start'>Create Content</h1>
      <p className='text-start'>Create Premium Content</p>
      {!isLoading && (
        <Row>
          <Col md={3} className='pe-4 '>
            <ListGroup className='bg-light'>
              <ListGroup.Item className={step === 0 ? 'bg-light' : ''}>
                <Button variant='link p-0' onClick={() => setStep(0)}>
                  1) Metadata
                </Button>
              </ListGroup.Item>
              <ListGroup.Item className={step === 1 ? 'bg-light' : ''}>
                <Button
                  variant='link p-0'
                  onClick={() => setStep(1)}
                  disabled={!currentContent?.title}
                >
                  2) Upload
                </Button>
              </ListGroup.Item>
              <ListGroup.Item className={step === 2 ? 'bg-light' : ''}>
                <Button
                  variant='link p-0'
                  onClick={() => setStep(2)}
                  disabled={!currentContent?.title}
                >
                  3) Description
                </Button>
              </ListGroup.Item>
              <ListGroup.Item className={step === 3 ? 'bg-light' : ''}>
                <Button
                  variant='link p-0'
                  onClick={() => setStep(3)}
                  disabled={!currentContent?.title}
                >
                  4) Groups
                </Button>
              </ListGroup.Item>
              <ListGroup.Item className={step === 4 ? 'bg-light' : ''}>
                <Button
                  variant='link p-0'
                  onClick={() => setStep(4)}
                  disabled={!currentContent?.title}
                >
                  5) Submit
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={9}>
            {step === 0 && (
              <ContentMeta
                next={_next}
                setCurrentContent={_setCurrentContent}
              />
            )}

            {step === 1 && (
              <ContentUpload
                next={_next}
                setCurrentContent={_setCurrentContent}
              />
            )}
            {step === 2 && (
              <ContentDescription
                next={_next}
                setCurrentContent={_setCurrentContent}
              />
            )}
          </Col>
        </Row>
      )}
      {isLoading && (
        <div className='text-center'>
          <Spinner animation='grow' />
        </div>
      )}
    </Container>
  )
}
