import { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactQuill, { Quill } from 'react-quill'
import { WrapService } from '../services/wrap.service'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import Actions from '../redux/actions'

const ImageResize = require('quill-image-resize-module-react')

// Quill.register('modules/imageResize', ImageResize)

interface RichTextEditorProps {
  onHTMLChange: (content: string) => void
  initialValue?: string
}
export default function RichTextEditor (props: RichTextEditorProps) {
  const quillRef = useRef<ReactQuill>(null)
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()
  const wrapService = new WrapService(dispatch)
  const [editorHtml, setEditorHtml] = useState(props.initialValue ?? '')

  let isUploading = false
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 500)

    const interval = setInterval(() => {
      _base64Searcher()
    }, 1500)

    return () => clearInterval(interval)
  }, [])

  const _base64Searcher = async () => {
    if (!isUploading) {
      const html = quillRef.current?.getEditor().root.innerHTML
      if (html) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        // Find all <img> tags with base64 source
        const images = Array.from(doc.querySelectorAll('img')).filter(img =>
          img.src.startsWith('data:image')
        )
        if (images.length > 0) {
          isUploading = true

          console.log(`FOUND ${images.length} pasted images`)

          const randomID = window.crypto.randomUUID()

          // Upload each image and replace its src with the uploaded URL
          for (const img of images) {
            const presignedData = await wrapService.getPresignedURL(
              `random-uploads`,
              `${randomID}.png`
            )
            if (presignedData.url) {
              console.log(img.src)
              const blob = base64ToBlob(img.src.split(',')[1])
              await axios.put(presignedData.url, blob)
              img.src = `https://carnivore-wrap-2.s3.amazonaws.com/${presignedData.key}`
            }
          }

          // Serialize the document back into an HTML string
          const serializer = new XMLSerializer()
          const updatedHtmlString = serializer.serializeToString(doc)
          quillRef.current.getEditor().root.innerHTML = updatedHtmlString
          isUploading = false
        }
      }
    }
  }

  function base64ToBlob (base64: string): Blob {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'image/png' })
  }

  const imageHandler = async () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null
      if (file) {
        const presignedData = await wrapService.getPresignedURL(
          `random-uploads`,
          file.name
        )
        console.log(presignedData)
        if (presignedData.url && presignedData.key) {
          // Upload the image to S3
          await uploadImageToS3(presignedData.url, presignedData.key, file)
          // Insert the image URL into the editor
          const range = quillRef.current?.getEditor().getSelection()

          if (range) {
            quillRef.current
              ?.getEditor()
              .insertEmbed(
                range.index,
                'image',
                `https://carnivore-wrap-2.s3.amazonaws.com/${presignedData.key}`
              )
          }
        }
      }
    }
  }

  const modules = {
    toolbar: {
      container: [
        [
          { font: [] },
          { size: ['small', false, 'large', 'huge'] },
          { header: [1, 2, 3, 4, 5, 6] }
        ], // Font size
        [{ align: [] }], // Text direction and alignment
        ['bold', 'italic', 'underline', 'strike'], // Bold, italic, underline, and strike
        [{ color: [] }, { background: [] }], // Text color and background
        [{ script: 'sub' }, { script: 'super' }], // Subscript and superscript
        [], // Heading
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ], // Lists
        ['link', 'video', 'formula'], // Links, images, videos, and formulas
        ['blockquote', 'code-block'] // Blockquote and code block
      ]
      // handlers: {
      //   image: imageHandler
      // },
      // imageResize: {}
    }
  }

  const uploadImageToS3 = async (
    url: string,
    key: string,
    file: File
  ): Promise<void> => {
    await axios.put(url, file)
    // const formData = new FormData()
    // formData.append('file', file)

    // await fetch(url, {
    //   method: 'PUT',
    //   body: formData,
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // })
  }

  const handleChange = (html: string) => {
    setEditorHtml(html)
    // Call the callback function passed from the parent component
    props.onHTMLChange(html)
  }

  return (
    <div>
      {show && (
        <ReactQuill
          onKeyDown={() => dispatch(Actions.setUnsavedChanged(true))}
          onChange={handleChange}
          value={editorHtml}
          ref={quillRef}
          modules={modules}
        />
      )}
    </div>
  )
}
