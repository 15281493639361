import { Alert, Modal } from 'react-bootstrap'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { FormEvent, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { StyleHelper } from '../../helpers/styler-helper'
import RichTextEditor from '../rich-text-editor'

export default function SendAlertModal (props: CarnivoreModalProps) {
  const error = useSelector((state: CarnivoreState) => state.errMsg)
  const [loading, setLoading] = useState(false)
  const [emailContent, setEmailContent] = useState('')
  const [subject, setSubject] = useState('')
  const [includeSMS, setIncludeSMS] = useState(false)
  const [smsContent, setSmsContent] = useState('')

  const [alertType, setAlertType] = useState('alert')
  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
  }

  const _copyFromEmail = () => {
    console.log(emailContent)
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = emailContent.replaceAll('<br>', '&nbsp;')
    let text = tempDiv.textContent || tempDiv.innerText || ''
    text = text.replace(/(\r\n|\n|\r)/gm, ' ')
    setSmsContent(text)
  }
  return (
    <Modal show={props.show} onHide={props.onClose} centered  size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Send New Alert</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant='danger my-3'>{error}</Alert>}

        <form onSubmit={_onSubmit}>
          <Grid container>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id='event-type-label'>Alert Type</InputLabel>
                <Select
                  labelId='event-type-label'
                  id='event-type-select'
                  value={alertType}
                  label='Alert Type'
                  onChange={e => setAlertType(e.target.value)}
                >
                  <MenuItem value='alert'>Alert</MenuItem>
                  <MenuItem value='update'>Update</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />

          <TextField
            label='Subject'
            placeholder='used in the email subject line'
            required
            variant='outlined'
            fullWidth
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <StyleHelper.VerticalSpace />
          <Typography variant='subtitle1' fontWeight={'bold'}>
            Email Content
          </Typography>
          <RichTextEditor
            initialValue={''}
            onHTMLChange={e => {
              setEmailContent(e)
            }}
          />
          <StyleHelper.VerticalSpace />

          <Divider />
          <StyleHelper.VerticalSpace />
          <Box display={'flex'} alignItems={'center'}>
            <Box>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Include SMS Campaign?
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeSMS}
                      onChange={e => setIncludeSMS(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={includeSMS ? 'YES' : 'NO'}
                />
              </FormGroup>
            </Box>

            <Button
              variant='contained'
              sx={{ marginLeft: 3 }}
              onClick={_copyFromEmail}
            >
              Copy from Email
            </Button>
          </Box>

          {includeSMS && (
            <>
              <StyleHelper.VerticalSpace />

              <TextField
                label='SMS Content'
                placeholder='content of sms message'
                required
                variant='outlined'
                multiline={true}
                rows={5}
                fullWidth
                value={smsContent}
                onChange={e => setSmsContent(e.target.value)}
              />
            </>
          )}
          <StyleHelper.VerticalSpace />

          <Button disabled={emailContent === ""} variant='contained' type='submit'>Send Alert</Button>
        </form>
      </Modal.Body>
    </Modal>
  )
}
