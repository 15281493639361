import { useDispatch } from 'react-redux'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { Container, Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import useBoolean from '../hooks/useBoolean'
import CreateLongTermModal from '../components/modal/create-longterm.modal'
import { LongTerm, LongTermStock } from '../_types/longterm.type'
import { LongTermService } from '../services/longterm.service'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { StyleHelper } from '../helpers/styler-helper'
import { Formatters } from '../helpers/formatters'
import { Cancel, CheckCircle, Close, Delete, Edit } from '@mui/icons-material'
import LongTermModal from '../components/modal/longterm-modal'

export default function LongTermPage(props: PageProps) {
  useDocumentTitle(props.title)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [currentLongTerm, setCurrentLongTerm] = useState<LongTerm | null>(null)
  const [editingStock, setEditingStock] = useState<LongTermStock | null>(null)
  const dispatch = useDispatch()
  const longTermService = new LongTermService(dispatch)

  useEffect(() => {
    _getLongTerm()
  }, [])
  const _getLongTerm = async () => {
    setLoading(true)

    const res = await longTermService.getLongTerms()
    if (res.longTerm) {
      setCurrentLongTerm(res.longTerm)
    }
    setLoading(false)
  }

  const _deleteStock = async (stock: LongTermStock) => {
    if (
      window.confirm(
        'Are you sure you want to remove this position. This cannot be undone'
      )
    ) {
      setLoading(true)
      let tempLongTerm: LongTerm = JSON.parse(JSON.stringify(currentLongTerm))
      tempLongTerm.stocks = tempLongTerm.stocks.filter(s => s.id !== stock.id)

      const res = await longTermService.updateLongTerm(tempLongTerm, Intl.DateTimeFormat().resolvedOptions().timeZone)
      if (res.longTerm) {
        setCurrentLongTerm(res.longTerm)
      }
      setLoading(false)
    }
  }
  return (
    <Container className='p-3'>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <Box>
          <Typography variant='h2'>Buy & Hold</Typography>
          <Typography variant='body1' textAlign={'start'}>
            Manage the long term portfolio
          </Typography>
        </Box>

        <Button
          variant='contained'
          color='success'
          onClick={() => {
            setEditingStock(null)
            setShowModal(true)
          }}
        >
          Add Stock
        </Button>
      </Box>
      <StyleHelper.VerticalSpace />

      {loading && <CircularProgress />}
      {!loading && (
        <>
          <Typography variant='subtitle1' fontWeight={'bold'} gutterBottom textAlign={'start'}>Active Stocks</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Ticker</TableCell>
                  <TableCell align='right'>Name</TableCell>
                  <TableCell align='right'>Entry Date</TableCell>

                  <TableCell align='right'>Entry Price</TableCell>
                  <TableCell align='right'>Current Price</TableCell>
                  <TableCell align='right'>StopLoss</TableCell>
                  <TableCell align='right'>Buy Up To</TableCell>
                  <TableCell align='right'>Commentary?</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentLongTerm?.stocks?.filter(stock => !stock.isSold)?.map(stock => (
                  <TableRow
                    key={stock.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f2f2f2'
                      }
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {stock.symbol}
                    </TableCell>
                    <TableCell align='right'>{stock.name}</TableCell>
                    <TableCell align='right'>
                      {new Date(stock.entryDate).toLocaleDateString('en-US', {
                        dateStyle: 'short'
                      })}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.entryPX)}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.currentPX ?? 0)}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.stopLoss)}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.buyUpTo)}
                    </TableCell>
                    <TableCell align='right'>
                      {stock.commentary && stock.commentary !== ''
                        ? <CheckCircle color='success' />
                        : <Cancel color='error' />}
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        title='Edit Position'
                        color='info'
                        onClick={() => {
                          setEditingStock(stock)
                          setShowModal(true)
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color='error'
                        title='Delete Position'
                        onClick={() => _deleteStock(stock)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <StyleHelper.DoubleVerticalSpace />
          <Typography variant='subtitle1' fontWeight={'bold'} gutterBottom textAlign={'start'}>Sold Stocks</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Ticker</TableCell>
                  <TableCell align='right'>Name</TableCell>
                  <TableCell align='right'>Entry Date</TableCell>
                  <TableCell align='right'>Sold Date</TableCell>

                  <TableCell align='right'>Entry Price</TableCell>
                  <TableCell align='right'>Sold Price</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentLongTerm?.stocks?.filter(stock => stock.isSold === true)?.map(stock => (
                  <TableRow
                    key={stock.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f2f2f2'
                      }
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {stock.symbol}
                    </TableCell>
                    <TableCell align='right'>{stock.name}</TableCell>
                    <TableCell align='right'>
                      {new Date(stock.entryDate).toLocaleDateString('en-US', {
                        dateStyle: 'short'
                      })}
                    </TableCell>
                    <TableCell align='right'>
                      {new Date(stock.soldDate ?? "").toLocaleDateString('en-US', {
                        dateStyle: 'short'
                      })}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.entryPX)}
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formatMoney(stock.soldAt ?? 0)}
                    </TableCell>

                    <TableCell align='right'>
                      <IconButton
                        title='Edit Position'
                        color='info'
                        onClick={() => {
                          setEditingStock(stock)
                          setShowModal(true)
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color='error'
                        title='Delete Position'
                        onClick={() => _deleteStock(stock)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody></Table>
          </TableContainer>
        </>
      )}
      <LongTermModal
        onUpdate={(update: LongTerm) => {
          setCurrentLongTerm(update)
        }}
        longTerm={currentLongTerm}
        initialStock={editingStock}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </Container>
  )
}
