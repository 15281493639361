import { Box, Button, CircularProgress, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { PageProps } from "../_types/page.props.interface";
import useDocumentTitle from "../helpers/title.helper";
import { useEffect, useState } from "react";
import { CTUser } from "../_types/user.type";
import { useDispatch } from "react-redux";
import { UserService } from "../services/user.service";
import { StyleHelper } from "../helpers/styler-helper";
import { FaWordpress } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
import UserDrawer from "../components/modal/view-user.modal";
import { Close, Search } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function UsersPage(props: PageProps) {
    useDocumentTitle(props.title)
    const [loading, setLoading] = useState(true)

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState<CTUser[]>([])
    const [hasNextPage, setHasNextPage] = useState(false)
    const [hasPrevPage, setHasPrevPage] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [totalDocs, setTotalDocs] = useState(0)
    const [refreshing, setRefreshing] = useState(true)


    const [currentUser, setCurrentUser] = useState<CTUser | undefined>()

    const [sortKey, setSortKey] = useState('email')
    const [sortOrder, setSortOrder] = useState('desc')

    const dispatch = useDispatch()
    const userService = new UserService(dispatch)


    useEffect(() => {
        _fetchUsers(1, sortOrder, sortKey, search)
    }, [])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            _fetchUsers(1, sortOrder, sortKey, search);
        }
    };


    const _fetchUsers = async (p: number, sortOrder: string, sortKey: string, search: string) => {
        setPage(p)
        setLoading(true)
        const results = await userService.queryUsers(p, sortKey, sortOrder, search)

        if (results?.docs) {
            setUsers(results.docs)
            setHasNextPage(results.hasNextPage)
            setHasPrevPage(results.hasPrevPage)
            setTotalPages(results.totalPages)
            setTotalDocs(results.totalDocs)
        }
        setRefreshing(false)
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }


    const _updateUserShown = async (user: CTUser) => {
        const tempArray: CTUser[] = JSON.parse(JSON.stringify(users))
        const index = tempArray.findIndex(u => u.id === user.id)
        tempArray[index] = user;
        setUsers(tempArray)
    }

    const _openWordpress = (wpID: string) => {
        window.open(
            `https://carnivoretrading.com/wp-admin/user-edit.php?user_id=${wpID}`,
            '_blank'
        )
    }

    const _handleClear = async () => {
        setSearch('')
        setSortKey('email')
        setSortOrder('desc')
        _fetchUsers(1, 'desc', 'email', '');

    }

    return <Container className='p-3'>
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'baseline'}
        >
            <Box>
                <Typography variant='h2' textAlign={'start'}>
                    Users
                </Typography>
                <Typography variant='body1' textAlign={'start'}>
                    View and manage users
                </Typography>
            </Box>
        </Box>
        <Divider />
        <StyleHelper.VerticalSpace />
        <Grid2 container spacing={2} alignItems={'center'}>
            <Grid2 md={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {search && (
                                    <IconButton onClick={_handleClear} aria-label="clear search">
                                        <Close />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid2>
            <Grid2 md={2}>
                <FormControl fullWidth>
                    <InputLabel id="sort-key-lbl">Sort Key</InputLabel>
                    <Select
                        labelId="sort-key-lbl"
                        id="sort-key"
                        value={sortKey}
                        label="Sort Key"
                        onChange={e => setSortKey(e.target.value)}
                    >
                        <MenuItem value={'email'}>Email</MenuItem>
                        <MenuItem value={'fName'}>First Name</MenuItem>
                        <MenuItem value={'lName'}>Last Name</MenuItem>
                        <MenuItem value={'lastSeen'}>Last Seen</MenuItem>
                        <MenuItem value={'created'}>Joined</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 md={2}>
                <FormControl fullWidth>
                    <InputLabel id="sort-order-lbl">Order By</InputLabel>
                    <Select
                        labelId="sort-order-lbl"
                        id="sort-order"
                        value={sortOrder}
                        label="Sort Order"
                        onChange={e => setSortOrder(e.target.value)}
                    >
                        <MenuItem value={'desc'}>Descending</MenuItem>
                        <MenuItem value={'asc'}>Ascending</MenuItem>

                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 md={2}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}

                    onClick={() => {
                        _fetchUsers(1, sortOrder, sortKey, search);

                    }}
                    startIcon={<Search />}
                >
                    Search
                </Button>
            </Grid2>
        </Grid2>

        <StyleHelper.VerticalSpace />
        {loading && <CircularProgress />}

        {!loading && (
            <>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='user table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Last Seen</TableCell>
                                <TableCell>Created</TableCell>

                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map(user => (
                                <TableRow
                                    key={user.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: '#f2f2f2'
                                        }
                                    }}
                                >
                                    <TableCell>{user.fName} {user.lName}</TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell>
                                        {user.phoneNumber}
                                    </TableCell>
                                    <TableCell>
                                        {user.lastSeen ? new Date(user.lastSeen).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }) : 'n/a'}
                                    </TableCell>
                                    <TableCell>
                                        {user.created ? new Date(user.created).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }) : 'n/a'}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <FaWordpress
                                                title='open in wordpress'
                                                className='pointer me-2 text-primary align-middle'
                                                onClick={() => _openWordpress(user.wordpressId ?? '')}
                                            />
                                            <span
                                                className='pointer me-2 my-auto align-middle'
                                                title='Open in Klaviyo'
                                                style={{ fontWeight: 'bolder' }}
                                                onClick={() =>
                                                    window.open(
                                                        `https://www.klaviyo.com/profile/${user.klaviyoID ?? ''
                                                        }`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                K
                                            </span>
                                            <MdOpenInNew title='View User'
                                                className='pointer me-2 text-secondary align-middle'
                                                onClick={() => setCurrentUser(user)} />

                                        </Stack>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <StyleHelper.VerticalSpace />
                <Stack spacing={2} justifyContent='center' alignItems='center'>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event: unknown, page: number) => {
                            _fetchUsers(page, sortOrder, sortKey, search)
                        }}
                        variant='outlined'
                        shape='rounded'
                    />
                </Stack>
            </>
        )}

        {currentUser && <UserDrawer updateUser={_updateUserShown} user={currentUser} isOpen={currentUser != undefined} closeDrawer={() => setCurrentUser(undefined)} />}


    </Container>
}