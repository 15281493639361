import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin/stat`,
  withCredentials: false
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class StatService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async getCancellations (startDate: string, endDate: string) {
    return axiosInstance
      .get('/cancellations', {
        params: {
          startDate,
          endDate
        }
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async totalRevenue (startDate: string, endDate: string) {
    return axiosInstance
      .get('/revenue', {
        params: {
          startDate,
          endDate
        }
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async newSubscribers (startDate: string, endDate: string) {
    return axiosInstance
      .get('/new-subs', {
        params: {
          startDate,
          endDate
        }
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
