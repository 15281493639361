import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import { PageProps } from '../_types/page.props.interface'
import { StyleHelper } from '../helpers/styler-helper'
import { AttachMoney, Money } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import LoadingBtn from '../components/loading-btn'
import { useDispatch } from 'react-redux'
import { SettingsService } from '../services/settings.service'
import { CarnivoreSettings } from '../_types/ssettings.type'
import Actions from '../redux/actions'

export default function PricingPage (props: PageProps) {
  const [monthlyPricing, setMonthlyPricing] = useState('')
  const [annualPricing, setAnnualPricing] = useState('')

  const [updateLoading, setUpdateLoading] = useState(false)

  const [fetching, setFetching] = useState(true)
  const dispatch = useDispatch()

  const settingsService = new SettingsService(dispatch)

  useEffect(() => {
    _fetchPricing()
  }, [])
  const _fetchPricing = async () => {
    const settingsRes = await settingsService.getSettings()
    if (settingsRes.settings) {
      const settings: CarnivoreSettings = settingsRes.settings

      const monthlyRes = await settingsService.getPrice(
        settings.proMonthlyPriceID
      )
      const annualRes = await settingsService.getPrice(
        settings.proYearlyPriceID
      )

      if (monthlyRes.price && annualRes.price) {
        setMonthlyPricing(String((monthlyRes.price / 100).toFixed(2)))
        setAnnualPricing(String((annualRes.price / 100).toFixed(2)))
      }

      setFetching(false)
    }
  }

  const _updatePrice = async (interval: string) => {
    setUpdateLoading(true)
    if (interval === 'year') {
      if (!annualPricing || isNaN(Number(annualPricing))) {
        dispatch(Actions.setError('Annual Pricing is not a number'))
        return
      }
      const res = await settingsService.updatePrice(multiplyAndRoundDown(Number(annualPricing)), 'year')

    }

    if (interval === 'month') {
      if (!monthlyPricing || isNaN(Number(monthlyPricing))) {
        dispatch(Actions.setError('Monthly Pricing is not a number'))
        return
      }
      const res = await settingsService.updatePrice(multiplyAndRoundDown(Number(monthlyPricing)), 'month')
      
    } 
    setUpdateLoading(false)
    dispatch(Actions.setToast('Price Updated', 'Pricing changes live immediately'))
    _fetchPricing()
  }

  function multiplyAndRoundDown (value: number): number {
    return Math.floor(value * 100)
  }
  return (
    <Container sx={{ p: 3, textAlign: 'start' }}>
      <h1>Pricing</h1>
      <p className='lead'>Edit new subscriber pricing</p>
      <StyleHelper.VerticalSpace />
      <Divider />
      <StyleHelper.VerticalSpace />
      {fetching && <CircularProgress />}
      {!fetching && (
        <Paper elevation={3}>
          <Box p={3}>
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              color={'primary'}
            >
              Current Rates
            </Typography>
            <Typography variant='body1'>
              Edit the rate for new subscribers. To edit current subscribers'
              rates, reach out to Tyler.
            </Typography>

            <StyleHelper.VerticalSpace />
            <Divider />
            <StyleHelper.VerticalSpace />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  fontWeight={'bold'}
                  color={'primary'}
                  gutterBottom
                >
                  Monthly
                </Typography>
                <StyleHelper.VerticalSpace />

                <TextField
                  variant='outlined'
                  label='Monthly Pricing'
                  type='number'
                  onChange={e => setMonthlyPricing(e.target.value)}

                  value={monthlyPricing}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AttachMoney />
                      </InputAdornment>
                    )
                  }}
                />
                <StyleHelper.VerticalSpace />
                <LoadingBtn
                  variant='primary'
                  type='submit'
                  disabled={updateLoading}
                  isLoading={updateLoading}
                  onClick={() => _updatePrice('month')}

                  text='Change Monthly'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  fontWeight={'bold'}
                  color={'primary'}
                  gutterBottom
                >
                  Annual
                </Typography>
                <StyleHelper.VerticalSpace />

                <TextField
                  variant='outlined'
                  label='Annual Pricing'
                  type='number'
                  value={annualPricing}
                  onClick={() => _updatePrice('month')}
                  onChange={e => setAnnualPricing(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AttachMoney />
                      </InputAdornment>
                    )
                  }}
                />
                <StyleHelper.VerticalSpace />
                <LoadingBtn
                  variant='primary'
                  type='submit'
                  disabled={updateLoading}
                  isLoading={updateLoading}
                  onClick={() => _updatePrice('year')}
                  text='Change Annual'
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Container>
  )
}
