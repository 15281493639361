export class Formatters {
  static formatMoney (amount: number): string {
    return (
      `$${Number(amount ?? 0)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ?? 'unavailable'}` 
    )
  }

  static formateLocalTZ (value: any): string {
    try {
      return new Date(value).toLocaleString('en-US', {
        dateStyle: 'short',
        timeStyle: 'short'
      })
    } catch (e) {
      return ''
    }
  }

  // Function to format a number into a percentage format
  static formatPercentage (percentage: number): string {
    return `${Number(percentage ?? 0).toFixed(2)}%`
  }

  static formatCommas (num: number) {
    return num.toLocaleString()
  }

  static removeHyphensAndUppercase (input: string) {
    return input
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  static isoStringToLocalTimeInput (isoString: string): string {
    const date = new Date(isoString)
    const pad = (n: number) => n.toString().padStart(2, '0')

    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1) // getMonth() returns month from 0 to 11
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())

    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  static formatDateTimeInput (value: any): string {
    try {
      if (value) {
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZoneName: 'short' // Optional: include the timezone name
        }

        const formattedDate = new Date(value)
          .toLocaleString('en-GB', options)
          .replace(',', 'T')
          .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$2-$1')
        return formattedDate
      }
      return ''
    } catch (e) {
      return ''
    }
  }
}
