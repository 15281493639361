import { Container, Form, ProgressBar } from 'react-bootstrap'
import { ContentProps } from '../../_types/contentprops'
import { useState } from 'react'
import CarnivoreFileInput from '../file-input'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import LoadingBtn from '../loading-btn'
import { CarnivoreContent } from '../../_types/carnivore-content.type'
import { FileService } from '../../services/file.service'
import axios from 'axios'
import Actions from '../../redux/actions'
import { ContentService } from '../../services/content.service'

export default function ContentUpload (props: ContentProps) {
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const dispatch = useDispatch()

  const fileService = new FileService(dispatch)

  const currentContent = useSelector(
    (state: CarnivoreState) => state.currentContent
  )

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }
  const contentService = new ContentService(dispatch)

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const droppedFile = e.dataTransfer.items[0].getAsFile()
      if (droppedFile) {
        setFile(droppedFile)
      }
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const uploadedFile = e.target.files[0]
      console.log(`File name: ${uploadedFile.name}`)
      setFile(uploadedFile)
    }
  }

  const _getAccept = (content?: CarnivoreContent) => {
    switch (content?.type) {
      case 'pdf':
        return '.pdf'
      default:
        return undefined
    }
  }

  const _upload = async () => {
    if (file) {
      setUploadProgress(0)
      setIsUploading(true)
      console.log(`IN FUNC FILE NAME: ${file.name}`)
      const link = await fileService.getPresignedUploadURL(
        `content/${currentContent?.type ?? 'unknown'}/${currentContent?.id ?? "uh-oh"}`,
        file.name
      )
      if (link.url && link.dlUrl && link.key) {
        await axios.put(link.url, file, {
          onUploadProgress: progressEvent => {
            const totalLength = file.size
            if (totalLength !== null) {
              setUploadProgress(
                Math.round((progressEvent.loaded * 100) / totalLength)
              )
            }
          }
        })

        const temp: CarnivoreContent = JSON.parse(
          JSON.stringify(currentContent ?? {})
        )

        temp.contentID = link.key
        const res = await contentService.updateContent(
          currentContent?.id ?? '',
          temp
        )
        props.setCurrentContent(res.content)
        dispatch(
          Actions.setToast('File Uploaded', 'your file has been uploaded')
        )
        props.next()
      }
      setIsUploading(false)
    }
  }

  return (
    <Container className='text-start'>
      <h2>Upload Content</h2>

      <Form.Label>Choose File</Form.Label>
      <div
        className='file-upload-container mb-2'
        onDragOver={handleDragOver}
        onDrop={e => handleDrop(e)}
      >
        <Form.Control
          id={`content-picker`}
          type='file'
          className='w-50'
          onChange={e =>
            handleFileChange(e as React.ChangeEvent<HTMLInputElement>)
          }
          accept={_getAccept(currentContent)}
        />
        <Form.Text>
          Accepting <em>{_getAccept(currentContent)}</em> files. Change type in
          previous step to change
        </Form.Text>
      </div>

      <LoadingBtn
        className='mt-3'
        disabled={file === null}
        isLoading={isUploading}
        onClick={_upload}
        type='button'
        variant='primary'
        text='Upload'
      />
      {isUploading && (
        <div className='mt-3'>
          <Form.Label>Upload Progress</Form.Label>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        </div>
      )}
    </Container>
  )
}
