import { useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import PAIModalProps from '../../_types/modal-props.type'
import CarnivoreModalProps from '../../_types/modal-props.type'

interface FullScreenImgModalProps extends CarnivoreModalProps {
  imgSrc: string
}

export default function FullScreenImgModal (props: FullScreenImgModalProps) {
  return (
    <Modal show={props.show} size='xl' centered onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Image Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <img className='img-fluid' alt='placeholder' src={props.imgSrc} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
