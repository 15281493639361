import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'
import { SectorHeater } from '../_types/sector-heaters.type'
import { CarnivoreEvent } from '../_types/carnivore-event.type'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/file`,
  withCredentials: false
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class FileService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async getPresignedUploadURL (
    prefix : string,
    name: string
  ): Promise<
    | { url: string; key: string; dlUrl: string }
    | { errMSg: string; url?: string; key?: string ; dlUrl?: string}
  > {
    return axiosInstance
      .post('/upload', {
        name,
        prefix
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
