import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import LoadingBtn from '../../loading-btn'
import DragFileInput from '../../drag-file-input'
import WrapDiscardBtn from '../wrap-discard-button'

export default function WrapSectors () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [technology, setTechnology] = useState('')
  const [energy, setEnergy] = useState('')
  const [industrials, setIndustrials] = useState('')
  const [materials, setMaterials] = useState('')
  const [realEstate, setRealEstate] = useState('')
  const [healthCare, setHealthCare] = useState('')
  const [financial, setFinancial] = useState('')
  const [communication, setCommunication] = useState('')
  const [utilities, setUtilities] = useState('')
  const [consumerDiscretionary, setConsumerDiscretionary] = useState('')
  const [consumerStaples, setConsumerStaples] = useState('')
  const [content, setContent] = useState('')
  const [copyLoading, setCopyLoading] = useState(false)

  const [heatMapSrc, setHeatMapSrc] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [flicker, setFlicker] = useState(true)

  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)
  const _onSubmit = async () => {
    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      sectors: {
        technology,
        energy,
        industrials,
        materials,
        realEstate,
        healthCare,
        financial,
        communication,
        utilities,
        consumerDiscretionary,
        consumerStaples
      },
      $set: {
        'contentBlocks.sectorBreakdown': content,
        'images.heatMapSrc': heatMapSrc
      }
    })
    console.log(resp)
    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
      console.log('setting toast')
    } else {
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentWrap) {
      setTechnology(currentWrap.sectors?.technology ?? '')
      setEnergy(currentWrap.sectors?.energy ?? '')
      setIndustrials(currentWrap.sectors?.industrials ?? '')
      setMaterials(currentWrap.sectors?.materials ?? '')
      setRealEstate(currentWrap.sectors?.realEstate ?? '')
      setHealthCare(currentWrap.sectors?.healthCare ?? '')
      setFinancial(currentWrap.sectors?.financial ?? '')
      setCommunication(currentWrap.sectors?.communication ?? '')
      setUtilities(currentWrap.sectors?.utilities ?? '')
      setConsumerDiscretionary(currentWrap.sectors?.consumerDiscretionary ?? '')
      setConsumerStaples(currentWrap.sectors?.consumerStaples ?? '')
      setContent(currentWrap.contentBlocks?.sectorBreakdown ?? '')
      setHeatMapSrc(currentWrap?.images?.heatMapSrc ?? '')
      setFlicker(false)
      setTimeout(() => {
        setFlicker(true)
      }, 200)
    }
  }, [currentWrap])

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Sectors</h4>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />

      <Row className='flex-wrap'>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Technology</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='technology commentary'
              rows={3}
              value={technology}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setTechnology(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Energy</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='energy commentary'
              rows={3}
              value={energy}
              onChange={e => {
                setEnergy(e.target.value)
                dispatch(Actions.setUnsavedChanged(true))
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Industrials</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Industrials commentary'
              rows={3}
              value={industrials}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setIndustrials(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Materials</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='materials commentary'
              rows={3}
              value={materials}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setMaterials(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Real Estate</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='real estate commentary'
              rows={3}
              value={realEstate}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setRealEstate(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Healthcare</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Healthcare commentary'
              rows={3}
              value={healthCare}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setHealthCare(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Financial</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Financial commentary'
              rows={3}
              value={financial}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setFinancial(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Communication</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Communication commentary'
              rows={3}
              value={communication}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setCommunication(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Utilities</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Utilities commentary'
              rows={3}
              value={utilities}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setUtilities(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Consumer Discretionary</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Consumer Discretionary commentary'
              rows={3}
              value={consumerDiscretionary}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setConsumerDiscretionary(e.target.value)
              }}
            />
          </div>
        </Col>

        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            <h5 className='text-start'>
              <strong>Consumer Staples</strong>
            </h5>
            <Form.Control
              as='textarea'
              placeholder='Consumer Staples commentary'
              rows={3}
              value={consumerStaples}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setConsumerStaples(e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md={4} className='p-2'>
          <div className='border p-3 rounded shadow'>
            {flicker && (
              <DragFileInput
                initialSrc={heatMapSrc ?? ''}
                imgType='heatMapSrc'
                label='HeatMap'
                onFileUploaded={imgSrc => setHeatMapSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className='text-start mt-2'>
            <Form.Label>Sector Breakdown</Form.Label>
           
          </div>
          {flicker && currentWrap && (
            <RichTextEditor
              initialValue={currentWrap?.contentBlocks?.sectorBreakdown ?? ''}
              onHTMLChange={e => {
               
                setContent(e)
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
