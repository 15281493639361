import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { FormEvent, useEffect, useState } from 'react'
import { LongTerm, LongTermStock } from '../../_types/longterm.type'
import { Alert, Modal } from 'react-bootstrap'
import { Button, Divider, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { StyleHelper } from '../../helpers/styler-helper'
import RichTextEditor from '../rich-text-editor'
import { LongTermService } from '../../services/longterm.service'
import LoadingBtn from '../loading-btn'
import LoadingButtonMUI from '../loading-btn copy'
import Actions from '../../redux/actions'
import { toZonedTime } from 'date-fns-tz'

interface LongTermModalProps extends CarnivoreModalProps {
  initialStock: LongTermStock | null
  longTerm: LongTerm | null
  onUpdate: (longTerm: LongTerm) => void
}

export default function LongTermModal(props: LongTermModalProps) {
  const error = useSelector((state: CarnivoreState) => state.errMsg)

  const [loading, setLoading] = useState(false)

  const [symbol, setSymbol] = useState('')
  const [name, setName] = useState('')
  const [entryDate, setEntryDate] = useState('')
  const [entryPx, setEntryPx] = useState('')
  const [stopLoss, setStopLoss] = useState('')
  const [buyUpTo, setBuyUpTo] = useState('')
  const [commentary, setCommentary] = useState('')

  const [isSold, setIsSold] = useState(false)
  const [soldDate, setSoldDate] = useState('')
  const [soldAt, setSoldAt] = useState('')

  const dispatch = useDispatch()
  const longTermService = new LongTermService(dispatch)

  useEffect(() => {
    if (props.initialStock) {
      const stock = props.initialStock
      setSymbol(stock.symbol)
      setName(stock.name)
      setEntryDate(_formatDate(stock.entryDate))
      setEntryPx(stock.entryPX.toString())
      setStopLoss(stock.stopLoss.toString())
      setBuyUpTo(stock.buyUpTo.toString())
      setCommentary(stock.commentary ?? '')

      setIsSold(stock.isSold ?? false)
      setSoldAt(stock.soldAt?.toString() ?? "")
      setSoldDate(_formatDate(stock.soldDate))
      console.log(stock)
    } else {
      setSymbol('')
      setName('')
      setEntryDate('')
      setEntryPx('')
      setStopLoss('')
      setBuyUpTo('')
      setCommentary('')
      setIsSold(false)
      setSoldAt('')
      setEntryDate('')
    }
  }, [props.show])

  const _formatDate = (value?: string) => {
    if (value) {
      try {
        const date = new Date(value)
        return date.toISOString().split("T")[0]
      } catch (e) {
        return ""
      }
    }

    return ""
  }

  function setToNoonEST(dateString: string): string {
    // Parse the input date string (assumed to be YYYY-MM-DD format)
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day, 17, 0, 0)); // EST is UTC-5, so 12:00 PM EST = 17:00 UTC

    return date.toISOString();
  }
  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const tempLongTerm: LongTerm = JSON.parse(JSON.stringify(props.longTerm))
    if (props.initialStock) {
      const index = tempLongTerm.stocks.findIndex(
        s => s.id === props.initialStock?.id
      )
      if (index >= 0) {
        tempLongTerm.stocks[index] = {
          id: props.initialStock.id,
          symbol: symbol.toUpperCase().trim(),
          name: name.trim(),
          entryDate: setToNoonEST(entryDate),
          entryPX: Number(entryPx),
          stopLoss: Number(stopLoss),
          buyUpTo: Number(buyUpTo),
          isSold: isSold,
          soldAt: isSold ? Number(soldAt) : undefined,
          soldDate: isSold ? setToNoonEST(soldDate) : undefined,

          commentary: commentary.trim() === '' ? undefined : commentary
        }
      }
    } else {
      tempLongTerm.stocks.push({
        symbol: symbol.toUpperCase().trim(),
        name: name.trim(),
        entryDate: setToNoonEST(entryDate),
        entryPX: Number(entryPx),
        stopLoss: Number(stopLoss),
        buyUpTo: Number(buyUpTo),
        isSold: isSold,
        soldAt: isSold ? Number(soldAt) : undefined,
        soldDate: isSold ? setToNoonEST(soldDate) : undefined,
        commentary: commentary.trim() === '' ? undefined : commentary
      })
    }

    const res = await longTermService.updateLongTerm(tempLongTerm, Intl.DateTimeFormat().resolvedOptions().timeZone)
    if (res.longTerm) {

      props.onUpdate(res.longTerm)
      dispatch(Actions.setToast(`Long Term Position Updated`, 'Changes are live immediately'))
      props.onClose()
    }
    setLoading(false)

  }

  return (
    <Modal show={props.show} onHide={props.onClose} centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.initialStock
            ? 'Edit Long Term Stock'
            : 'Create Long Term Stock'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant='danger my-3'>{error}</Alert>}
        <form onSubmit={_onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label='Symbol'
                placeholder='AMZN'
                required
                variant='outlined'
                fullWidth
                value={symbol}
                onChange={e => setSymbol(e.target.value.trim().toUpperCase())}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Name'
                placeholder='Amazon'
                required
                variant='outlined'
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Entry Date'
                type='date'
                fullWidth
                value={entryDate}
                onChange={e => setEntryDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label='Entry Price'
                placeholder='00.00'
                required
                type='number'
                variant='outlined'
                fullWidth
                value={entryPx}
                onChange={e => setEntryPx(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Buy Up To'
                placeholder='00.00'
                required
                type='number'
                variant='outlined'
                fullWidth
                value={buyUpTo}
                onChange={e => setBuyUpTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Stop Loss'
                placeholder='00.00'
                required
                type='number'
                variant='outlined'
                fullWidth
                value={stopLoss}
                onChange={e => setStopLoss(e.target.value)}
              />
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />
          <Divider />
          <StyleHelper.VerticalSpace />
          <Typography variant='subtitle1' fontWeight={'bold'}>Selling Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSold}
                    onChange={e => setIsSold(e.target.checked)}
                    color="primary"
                  />
                }
                label={isSold ? "Sold" : "Active"}
              />
            </Grid>
            {isSold && <>
              <Grid item xs={4}>
                <TextField
                  label='Sold Date'
                  type='date'
                  fullWidth
                  value={soldDate}
                  onChange={e => setSoldDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label='Sold At'
                  placeholder='00.00'
                  required
                  type='number'
                  variant='outlined'
                  fullWidth
                  value={soldAt}
                  onChange={e => setSoldAt(e.target.value)}
                />
              </Grid>
            </>}
          </Grid>

          <StyleHelper.VerticalSpace />
          <Divider />
          <StyleHelper.VerticalSpace />

          <TextField
            label='Commentary (Optional)'
            placeholder='Give more context to the stock pick'
            variant='outlined'
            multiline={true}
            rows={8}
            fullWidth
            value={commentary}
            onChange={e => setCommentary(e.target.value)}
          />

          <StyleHelper.VerticalSpace />

          <LoadingButtonMUI
            variant='contained'
            type='submit'
            disabled={loading}
            loading={loading}
          >
            {props.initialStock ? 'Update' : 'Create'} Stock
          </LoadingButtonMUI>
        </form>
      </Modal.Body>
    </Modal>
  )
}
