export enum WrapBuilderPage {
  meta = 'meta',
  traderZ = 'traderZ',
  performance = 'performance',
  portfolio = 'portfolio',
  specialtyTrades = 'specialtyTrades',
  positions = 'positions',
  headlines = 'headlines',
  index = 'index',
  sectors = 'sectors',
  summary = 'summary',
  amSession = 'amSession',
  pmSession = 'pmSession',
  commentary = 'commentary',
  babes = 'babes',
  bonus = 'bonus',
  publish = 'publish'
}
