import { useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import PAIModalProps from '../../_types/modal-props.type'
import CarnivoreModalProps from '../../_types/modal-props.type'

interface ConfirmModalProps extends CarnivoreModalProps {
  header: string
}

export default function LoadingModal (props: ConfirmModalProps) {
  const [loading, setLoading] = useState(false)

  return (
    <Modal show={props.show}  centered>
      <Modal.Header>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='text-center'>
          <Spinner animation='border' />
        </div>
      </Modal.Body>
    </Modal>
  )
}
