import { Container } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { StyleHelper } from '../helpers/styler-helper'
import { Formatters } from '../helpers/formatters'

import { Campaign, ShowChart } from '@mui/icons-material'
import { CarnivoreAlert } from '../_types/alert.type'
import { AlertService } from '../services/alert.service'
import SendAlertModal from '../components/modal/sendalert-modal'

export default function AlertsPage (props: PageProps) {
  useDocumentTitle(props.title)

  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [refreshing, setRefreshing] = useState(true)
  const [loading, setLoading] = useState(true)
  const [alerts, setAlerts] = useState<CarnivoreAlert[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalDocs, setTotalDocs] = useState(0)

  const dispatch = useDispatch()
  const alertService = new AlertService(dispatch)

  useEffect(() => {
    _fetchAlerts(1)
  }, [])

  const _fetchAlerts = async (p: number) => {
    setPage(p)

    const results = await alertService.searchAlerts(p)

    if (results?.docs) {
      setAlerts(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
      setTotalDocs(results.totalDocs)
    }
    setRefreshing(false)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const _getIcon = (alert: CarnivoreAlert) => {
    if (alert.alertType === 'alert') {
      return <Campaign />
    } else {
      return <ShowChart />
    }
  }

  return (
    <Container className='p-3'>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <Box>
          <Typography variant='h2' textAlign={'start'}>
            Alerts
          </Typography>
          <Typography variant='body1'>View, and create new alerts</Typography>
        </Box>
        <Button
          variant='contained'
          color='success'
          onClick={() => setShowModal(true)}
        >
          Send New Alert
        </Button>
      </Box>

      <Divider />

      <StyleHelper.VerticalSpace />
      {!loading && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Subject</TableCell>
                  <TableCell align='right'>Short</TableCell>

                  <TableCell align='right'>Type</TableCell>
                  <TableCell align='right'>Created</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alerts?.map(alert => (
                  <TableRow
                    key={alert.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f2f2f2'
                      }
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {alert.subject}
                    </TableCell>

                    <TableCell align='right'>{alert.short}</TableCell>
                    <TableCell align='right'>
                      <Chip
                        label={Formatters.removeHyphensAndUppercase(
                          alert.alertType
                        )}
                        icon={_getIcon(alert)}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      {Formatters.formateLocalTZ(alert.created)}
                    </TableCell>
                    <TableCell align='right'>
                      {alert.campaignId && (
                        <IconButton aria-label='delete' title='View in Klaviyo' onClick={() => window.open(`https://www.klaviyo.com/campaign/${alert.campaignId}/reports/overview`, "_blank")}>
                          <Campaign />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyleHelper.VerticalSpace />
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event: unknown, page: number) => {
                _fetchAlerts(page)
              }}
              variant='outlined'
              shape='rounded'
            />
          </Stack>
        </>
      )}
      {loading && <CircularProgress />}

      <SendAlertModal show={showModal} onClose={() => setShowModal(false)} />
    </Container>
  )
}
