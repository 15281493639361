import { Alert, Col, Form, Modal, Row } from 'react-bootstrap'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { FormEvent, useState } from 'react'
import LoadingBtn from '../loading-btn'

export default function PremiumContentModal (props: CarnivoreModalProps) {
  const error = useSelector((state: CarnivoreState) => state.errMsg)
  const [loading, setLoading] = useState(false)
  const [contentType, setContentType] = useState('')
  const [title, setTitle] = useState('')
  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
  }

  return (
    <Modal show={props.show} centered static size='lg'>
      <Modal.Header>
        <Modal.Title>Premium Content</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant='danger my-3'>{error}</Alert>}

        <Form onSubmit={_onSubmit}>
          <Form.Group className='mt-3 text-start'>
            <Form.Label className='text-start'>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder='name of piece'
            />
          </Form.Group>
          <Form.Group className='mt-3 text-start'>
            <Form.Label className='text-start'>Content Type</Form.Label>
            <Row>
              <Col md={5}>
                <Form.Select
                  value={title}
                  required
                  onChange={e => setTitle(e.target.value)}
                >
                  <option value={''}>Select Type</option>
                  <option value={'video'}>Video</option>
                  <option value={'presentation'}>Presentation</option>
                  <option value={'pdf'}>PDF</option>
                  <option value={'file'}>File</option>
                  <option value={'link'}>Link</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          <div className='text-center'>
            <LoadingBtn
              text='Submit'
              variant='primary'
              disabled={loading}
              isLoading={loading}
              type='submit'
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
