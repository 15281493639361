import { Container } from 'react-bootstrap'
import { ContentProps } from '../../_types/contentprops'
import RichTextEditor from '../rich-text-editor'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import LoadingBtn from '../loading-btn'
import { ContentService } from '../../services/content.service'
import { CarnivoreContent } from '../../_types/carnivore-content.type'

export default function ContentDescription (props: ContentProps) {
  const [content, setContent] = useState('')
  const [flicker, setFlicker] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const contentService = new ContentService(dispatch)
  const currentContent = useSelector(
    (state: CarnivoreState) => state.currentContent
  )

  useEffect(() => {
    if (currentContent) {
      setContent(currentContent.descHTML ?? '')
    }
    setFlicker(true)
  }, [currentContent])

  const _submit = async () => {
    setIsLoading(true)
    const temp: CarnivoreContent = JSON.parse(JSON.stringify(currentContent))
    temp.descHTML = content

    const res = await contentService.updateContent(
      currentContent?.id ?? '',
      temp
    )
    if (res.content) {
      props.setCurrentContent(res.content)
    }
    setIsLoading(false)
  }
  return (
    <Container className='text-start'>
      <h2>Description</h2>
      <p>Set The Description of the content piece.</p>
      {flicker && (
        <RichTextEditor
          initialValue={content}
          onHTMLChange={e => {
            setContent(e)
          }}
        />
      )}
      <LoadingBtn
        className='mt-3'
        type='button'
        variant='primary'
        disabled={content === ''}
        isLoading={isLoading}
        onClick={_submit}
        text='Save'
      />
    </Container>
  )
}
