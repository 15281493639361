import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { useEffect, useState } from 'react'
import { StatService } from '../services/stat.service'
import { useDispatch } from 'react-redux'

export default function Dashboard (props: PageProps) {
  useDocumentTitle(props.title)

  const [loading, setLoading] = useState(true)
  const [timeframe, setTimeFrame] = useState('TODAY')

  const [newSubs, setNewSubs] = useState(0)
  const [cancellations, setCancellations] = useState(0)
  const [revenue, setRevenue] = useState(0)

  const dispatch = useDispatch()
  const statService = new StatService(dispatch)

  useEffect(() => {
    _fetchForTimeFrame('TODAY')
  }, [])

  const _fetchForTimeFrame = async (time: string) => {
    setLoading(true)
    setTimeFrame(time)
    let daysToSubtract = 0
    switch (time) {
      case 'TODAY':
        daysToSubtract = 0
        break
      case '3 DAYS':
        daysToSubtract = 3
        break
      case '7 DAYS':
        daysToSubtract = 7
        break
      case '2 WEEKS':
        daysToSubtract = 14
        break
    }

    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(endDate.getDate() - daysToSubtract)

    const formattedEndDate = endDate.toISOString().split('T')[0]
    const formattedStartDate = startDate.toISOString().split('T')[0]

    const promises: any[] = []
    promises.push(
      statService
        .getCancellations(formattedStartDate, formattedEndDate)
        .then((d: any) => {
          console.log(d)
          if (d.cancelledCount !== null) setCancellations(d.cancelledCount)
        })
    )
    promises.push(
      statService
        .totalRevenue(formattedStartDate, formattedEndDate)
        .then((d: any) => {
          console.log(d)

          if (d.revenue !== null) setRevenue(d.revenue)
        })
    )
    promises.push(
      statService
        .newSubscribers(formattedStartDate, formattedEndDate)
        .then((d: any) => {
          console.log(d)
          if (d.newSubscribers !== null) setNewSubs(d.newSubscribers)
        })
    )

    await Promise.allSettled(promises)
    setLoading(false)
  }
  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h1 className='text-start'>Dashboard</h1>
          <p className='text-start'>
            Welcome to the new and improved Carnivore Control Hub
          </p>
        </Col>
        <Col>
          <Row className='justify-content-end'>
            <Col md={4}>
              <Form.Group className='mb-3 text-start'>
                <Form.Label>Time Frame</Form.Label>
                <Form.Select
                  disabled={loading}
                  value={timeframe}
                  onChange={e => _fetchForTimeFrame(e.target.value)}
                  aria-label='Default select example'
                >
                  <option value='TODAY'>TODAY</option>
                  <option value='3 DAYS'>3 DAYS</option>
                  <option value='7 DAYS'>7 DAYS</option>
                  <option value='2 WEEKS'>2 WEEKS</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className='p-3 text-end'>
          <div className='border '>
            <div className='py-1 bg-primary' />
            <div className='p-3'>
              <h5 className='text-muted'>New Revenue</h5>
              {loading ? (
                <Spinner animation='border' />
              ) : (
                <h4 className='display-4'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(revenue)}
                </h4>
              )}
            </div>
          </div>
        </Col>
        <Col className='p-3 text-end'>
          <div className='border '>
            <div className='py-1 bg-primary' />
            <div className='p-3'>
              <h5 className='text-muted'>New Subscribers</h5>
              {loading ? (
                <Spinner animation='border' />
              ) : (
                <h4 className='display-4'>{newSubs}</h4>
              )}
            </div>
          </div>
        </Col>
        <Col className='p-3 text-end'>
          <div className='border '>
            <div className='py-1 bg-primary' />
            <div className='p-3'>
              <h5 className='text-muted'>Cancellations</h5>
              {loading ? (
                <Spinner animation='border' />
              ) : (
                <h4 className='display-4'>{cancellations}</h4>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <h4>Other cool stuff coming here soon</h4>
      {/* <div className='bg-white border p-3 rounded shadow'>
        <h4>Quick Actions</h4>
        <Row>
          <Col className='p-3'>
            <div className='border '>
              <div className='py-1 bg-primary' />
              <div className='p-3'>
                <h5>Alerts</h5>
                <Button variant='primary'>Send Alert</Button>
              </div>
            </div>
          </Col>
          <Col className='p-3'>
            <div className='border '>
              <div className='py-1 bg-secondary' />
              <div className='p-3'>
                <h5>Users</h5>
                <Button variant='secondary'>View Users</Button>
              </div>
            </div>
          </Col>
          <Col className='p-3'>
            <div className='border '>
              <div className='py-1 bg-danger' />
              <div className='p-3'>
                <h5>Daily Wrap</h5>
                <Button variant='danger'>New Wrap</Button>
                <Button variant='danger ms-3'>View Wrap</Button>

              </div>
            </div>
          </Col>
        </Row>
      </div> */}
    </Container>
  )
}
