interface NumberCardProps {
  variant: string
  value: number
  label: string
}

export default function NumberCard (props: NumberCardProps) {
  return (
    <div className={`bg-light text-end border shadow`}>
      <div className={`w-100 py-1 bg-${props.variant}`} />
      <div className='p-3 pb-1'>
        <h5>{props.label}</h5>
        <p className='display-5 mb-0'>{props.value}</p>
      </div>
    </div>
  )
}
