import { FormEvent, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreContent } from '../../_types/carnivore-content.type'
import Actions from '../../redux/actions'
import { ContentProps } from '../../_types/contentprops'
import LoadingBtn from '../loading-btn'
import { ContentService } from '../../services/content.service'
import { QueryHelper } from '../../helpers/queryparams.helper'

export default function ContentMeta (props: ContentProps) {
  const [title, setTitle] = useState('')
  const [contentType, setContentType] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const currentContent = useSelector(
    (state: CarnivoreState) => state.currentContent
  )

  const dispatch = useDispatch()
  const contentService = new ContentService(dispatch)
  useEffect(() => {
    if (currentContent) {
      setTitle(currentContent.title ?? '')
      setContentType(currentContent.type ?? '')
    }
  }, [currentContent])

  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const temp: CarnivoreContent = JSON.parse(
      JSON.stringify(currentContent ?? {})
    )
    temp.title = title
    temp.type = contentType

    setIsLoading(true)
    if (!currentContent?.id) {
      //create one
      const res = await contentService.createContent(title, contentType)
      if (res.content) {

        props.setCurrentContent(res.content)
        props.next()
        QueryHelper.updateQueryParam('id', res.content.id)
        setIsLoading(false)
      }
    } else {
      //
      const res = await contentService.updateContent(
        currentContent.id ?? '',
        temp
      )
      props.setCurrentContent(res.content)
      setIsLoading(false)
      dispatch(Actions.setToast('Content Updated', ''))
    }
  }
  return (
    <Container className='text-start'>
      <h2>MetaData</h2>
      <Form onSubmit={_onSubmit}>
        <Form.Group className='mt-3 text-start'>
          <Form.Label className='text-start'>Title</Form.Label>
          <Form.Control
            value={title}
            required
            onChange={e => setTitle(e.target.value)}
            placeholder='Public Name of Content'
          />
        </Form.Group>

        <Form.Group className='mt-3 text-start'>
          <Form.Label className='text-start'>Content Type</Form.Label>
          <Row>
            <Col md={5}>
              <Form.Select
                value={contentType}
                required
                onChange={e => setContentType(e.target.value)}
              >
                <option value={''}>Select Type</option>
                <option value={'video'}>Video</option>
                <option value={'pdf'}>PDF</option>
                <option value={'link'}>Link</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <LoadingBtn
          variant='primary'
          className='mt-4'
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text='Save'
        />
      </Form>
    </Container>
  )
}
