import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { PageProps } from '../_types/page.props.interface'
import { StyleHelper } from '../helpers/styler-helper'
import CarnivoreLogo from '../components/imgs/carnivorelogo'
import { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BannerService } from '../services/banner.service'
import Actions from '../redux/actions'

export default function BannerPage (props: PageProps) {
  const [loading, setLoading] = useState(true)

  const [title, setTitle] = useState('')
  const [msg, setMsg] = useState('')
  const [expires, setExpires] = useState('')

  const [banner, setBanner] = useState<{
    msg: string
    title: string
    id: string
    expires: string
  } | null>(null)

  const dispatch = useDispatch()
  const bannerService = new BannerService(dispatch)

  useEffect(() => {
    _getCurrentBanner()
  }, [])

  const _getCurrentBanner = async () => {
    setLoading(true)
    const res = await bannerService.getBanner()
    setBanner(res.banner)
    setLoading(false)
  }

  const _deleteBanner = async () => {
    if (
      window.confirm('Are you sure? This action cannot be undone') &&
      banner
    ) {
      setLoading(true)
      const res = await bannerService.deleteBanner(banner.id)
      setBanner(null)
      setLoading(false)
      dispatch(Actions.setToast('Banner Deleted', 'The banner will no longer appear to Carnivores'))
    }
  }

  const _createBanner = async (e: FormEvent) => {
    e.preventDefault()
    let shouldCreate = true
    if (banner) {
      if (
        !window.confirm(
          'This will override your current banner, are you sure you want to update this?'
        )
      ) {
        shouldCreate = false
      }
    }
    if (shouldCreate) {
      setLoading(true)
      if(banner){
        await bannerService.deleteBanner(banner.id)

      }
      const res = await bannerService.createBanner(title, msg, Number(expires))
      if (res.banner) {
        setBanner(res.banner)
        setTitle('')
        setMsg('')
        setExpires('')
        dispatch(Actions.setToast('Banner Created', 'Your banner is now live'))
      }
      setLoading(false)
    }
  }

  return (
    <Container sx={{ p: 3, textAlign: 'start' }}>
      <h1 className='text-start'>Banner</h1>
      <p className='text-start'>
        Control an important message displayed at the top of the Dashboard.
      </p>
      <Divider />
      <StyleHelper.DoubleVerticalSpace />

      <Typography variant='h5' fontWeight={'bold'}>
        Currently Showing Banner
      </Typography>
      <StyleHelper.VerticalSpace />
      {loading && (
        <>
          <CircularProgress />
        </>
      )}
      {!loading && (
        <>
          {banner ? (
            <Box>
              <Paper elevation={3}>
                <Box p={3}>
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={4} lg={1}>
                      <Box
                        width={50}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                      >
                        <CarnivoreLogo />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={10}>
                      <Typography
                        variant='subtitle2'
                        fontWeight={'bold'}
                        color={'primary'}
                      >
                        Important Message
                      </Typography>
                      <Typography variant='subtitle1' fontWeight={'bold'}>
                        {banner?.title}
                      </Typography>
                      <Typography variant='body1'>{banner?.msg}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <StyleHelper.VerticalSpace />
              <Typography variant='body2' gutterBottom>
                Expires:{' '}
                {new Date(banner.expires).toLocaleString('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short'
                })}
              </Typography>
              <Button variant='contained' onClick={_deleteBanner} color='error'>
                Delete Active Banner
              </Button>
              <StyleHelper.VerticalSpace />
            </Box>
          ) : (
            <Typography variant='body2' gutterBottom>
              No Active Banner
            </Typography>
          )}
          <StyleHelper.VerticalSpace />

          <Divider />
          <Typography variant='h5' fontWeight={'bold'}>
            Create New Banner
          </Typography>
          <StyleHelper.VerticalSpace />
          <form onSubmit={_createBanner}>
            <Grid container spacing={2}>
              <Grid item lg={5}>
                <TextField
                  required
                  label='Banner Title'
                  value={title}
                  fullWidth
                  onChange={e => setTitle(e.target.value)}
                />
                <StyleHelper.VerticalSpace />
                <FormControl sx={{ width: '50%' }}>
                  <InputLabel id='expiration-lbl'>Expiration</InputLabel>
                  <Select
                    labelId='expiration-lbl'
                    id='demo-simple-select'
                    value={expires}
                    label='Age'
                    required
                    onChange={e => setExpires(e.target.value)}
                  >
                    <MenuItem value={''}>Select Expiration</MenuItem>
                    <MenuItem value={1}>1 Hour</MenuItem>
                    <MenuItem value={6}>6 Hours</MenuItem>
                    <MenuItem value={24}>1 Day</MenuItem>
                    <MenuItem value={72}>3 Days</MenuItem>
                    <MenuItem value={24 * 7}>1 Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={7}>
                <TextField
                  id='outlined-multiline-flexible'
                  label='Message'
                  multiline
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 250
                  }}
                  helperText={`${msg.length}/250`}
                  minRows={4}
                  value={msg}
                  onChange={e => setMsg(e.target.value)}
                  maxRows={4}
                />
              </Grid>
            </Grid>
            <StyleHelper.VerticalSpace />
            <Button type='submit' variant='contained'>
              Create Banner
            </Button>
          </form>
        </>
      )}
    </Container>
  )
}
