import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'
import { SectorHeater } from '../_types/sector-heaters.type'
import { CarnivoreEvent } from '../_types/carnivore-event.type'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/event`,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class EventService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async deleteEvent (id: string) {
    return axiosInstance
      .delete(`/${id}`)
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async updateEvent (id: string, event: any, tz: string) {
    return axiosInstance
      .put('/', {
        id,
        event,
        tz
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async createEvent (event: any, tz: string) {
    return axiosInstance
      .post('/', {
        event,
        tz
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async searchEvents (
    page: number,
    startDate?: string,
    endDate?: string,
    eventType?: string,
    limit?: number
  ) {
    return axiosInstance
      .get('/search', {
        params: {
          startDate,
          endDate,
          page,
          eventType,
          limit
        }
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async getPresignedUploadURL (
    name: string
  ): Promise<
    | { url: string; key: string }
    | { errMSg: string; url?: string; key?: string }
  > {
    return axiosInstance
      .post('/presign-upload', {
        name
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
