import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: true
})

axiosInstance.interceptors.request.use(
    async req => {
        // Add configurations here
        req.headers['Authorization'] =
            'Bearer: ' + localStorage.getItem('carnivore-token')
        return req
    },
    err => {
        return Promise.reject(err)
    }
)

export class UserMockService {
    private dispatch: Dispatch
    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }

    async getStripeSubscription(
        userId: string
    ) {
        return axiosInstance
            .get('/payment/subscription', {
                headers: {
                    'ct-mock-user-id': userId
                }
            })
            .then(res => res?.data?.data)
            .catch(e => apiErrorHandler(e, this.dispatch))
    }

    async getWooSubscription(
        userId: string
    ) {
        return axiosInstance
            .get('/payment/woo/subscription', {
                headers: {
                    'ct-mock-user-id': userId
                }
            })
            .then(res => res?.data?.data)
            .catch(e => apiErrorHandler(e, this.dispatch))
    }

    async getKlaviyoStatus(
        userId: string
    ) {
        return axiosInstance
            .put('/user/klaviyo-health', {

            }, {
                headers: {
                    'ct-mock-user-id': userId
                }
            })
            .then(res => res?.data?.data)
            .catch(e => apiErrorHandler(e, this.dispatch))
    }
}
