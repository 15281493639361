import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import LoadingModal from './modal/loading.modal'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../services/wrap.service'
import { CarnivoreState } from '../_types/carnivore-state.type'
import axios from 'axios'
import FullScreenImg from './fullscreen-img'
import Actions from '../redux/actions'

interface DragFileInputProps {
  label: string
  imgType: string
  initialSrc: string
  onFileUploaded: (imgSrc: string | null) => void
}

export default function DragFileInput (props: DragFileInputProps) {
  const [file, setFile] = useState<File | null>(null)
  const [showLoad, setShowLoad] = useState(false)
  console.log(`INSIDE HERE: ${props.initialSrc}`)
  const [imgSrc, setImgSrc] = useState(props.initialSrc ?? '')
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const dispatch = useDispatch()
  const wrapService = new WrapService(dispatch)

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const droppedFile = e.dataTransfer.items[0].getAsFile()
      if (droppedFile) {
        setFile(droppedFile)
        _uploadFile(droppedFile.name, droppedFile)
      }
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const uploadedFile = e.target.files[0]
      setFile(uploadedFile)
      _uploadFile(uploadedFile.name, uploadedFile)
    }
  }

  const _uploadFile = async (name: string, file: File) => {
    setShowLoad(true)
    const link = await wrapService.getPresignedURL(
      `${currentWrap?.formattedDate}/${props.imgType}`,
      name
    )
    if (link.url) {
      try {
        await axios.put(link.url, file)
        setImgSrc(`https://carnivore-wrap-2.s3.amazonaws.com/${link.key}`)
        props.onFileUploaded(
          `https://carnivore-wrap-2.s3.amazonaws.com/${link.key}`
        )
      } catch (e) {
        dispatch(Actions.setError('Error Uploading File'))
      }
    } else {
      dispatch(Actions.setError('Error Uploading File'))
    }
    dispatch(Actions.setUnsavedChanged(true))
    setShowLoad(false)
  }

  const _removeImg = () => {
    setFile(null)
    setImgSrc('')
    const input: any = document.getElementById(`${props.imgType}-picker`)
    if (input) {
      input.value = ''
    }
    props.onFileUploaded(null)
  }

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <div
        className='file-upload-container mb-2'
        onDragOver={handleDragOver}
        onDrop={e => handleDrop(e)}
      >
        <Form.Control
          id={`${props.imgType}-picker`}
          type='file'
          onChange={e =>
            handleFileChange(e as React.ChangeEvent<HTMLInputElement>)
          }
          accept='.jpg,.jpeg,.png,.gif'
        />
      </div>

      {imgSrc !== '' && <FullScreenImg imgSrc={imgSrc} />}
      {imgSrc && (
        <div className='text-center'>
          <Button onClick={_removeImg} variant='danger mt-2'>
            Remove
          </Button>
        </div>
      )}
      <LoadingModal
        onClose={() => setShowLoad(false)}
        header={'Uploading Image'}
        show={showLoad}
      />
    </>
  )
}
