import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import DragFileInput from '../../drag-file-input'
import LoadingBtn from '../../loading-btn'
import WrapDiscardBtn from '../wrap-discard-button'

export default function WrapPerformance () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [loading, setLoading] = useState(false)
  const [performanceSrc, setPerformanceSrc] = useState<string | null>(null)

  const [ytd, setYtd] = useState('')
  const [startValue, setStartValue] = useState('')
  const [endValue, setEndValue] = useState('')

  const [profitSold, setProfitSold] = useState('')
  const [lossSold, setLossSold] = useState('')

  const [file, setFile] = useState<File | null>(null)
  const [flicker, setFlicker] = useState(true)
  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)

  const _onSubmit = async () => {
    if (
      !ytd ||
      !performanceSrc ||
      !startValue ||
      !endValue ||
      !profitSold ||
      !lossSold
    ) {
      dispatch(
        Actions.setError(
          'Year to Date, Model Performance Image, Start Value, End Value, Profit sold, and loss sold are all required'
        )
      )
      return
    }
    try {
      Number(ytd)
      Number(startValue)
      Number(endValue)
      Number(profitSold)
      Number(lossSold)
    } catch (e) {
      dispatch(
        Actions.setError(
          'YTD, startValue, end value, sold for profit, and sold for loss must be a valid numbers'
        )
      )
      return
    }
    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      currentYTD: Number(ytd),
      startValue: Number(startValue),
      endValue: Number(endValue),
      profitSold: Number(profitSold),
      lossSold: Number(lossSold),
      images: {
        performanceSrc
      }
    })
    console.log(resp)
    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
      console.log('setting toast')
    } else {
      dispatch(Actions.setError('An Unknown error occurred'))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentWrap) {
      console.log(`Performance Src: ${currentWrap?.images?.performanceSrc}`)
      setPerformanceSrc(currentWrap?.images?.performanceSrc ?? '')
      setYtd(String(currentWrap?.currentYTD ?? ''))
      setStartValue(String(currentWrap?.startValue ?? ''))
      setEndValue(String(currentWrap?.endValue ?? ''))
      setProfitSold(String(currentWrap?.profitSold ?? ''))
      setLossSold(String(currentWrap?.lossSold ?? ''))

      setFlicker(false)
      setTimeout(() => {
        setFlicker(true)
      }, 200)
    }
  }, [currentWrap])

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Performance</h4>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />

      <Row className=' flex-wrap'>
        <Col md={4} className='m-3'>
          <div className='p-3 rounded border shadow text-start'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>YTD Performance</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  type='number'
                  placeholder='YTD'
                  value={ytd}
                  onChange={e => {
                    e.target.value ? setYtd(e.target.value) : setYtd('')
                    dispatch(Actions.setUnsavedChanged(true))
                  }}
                />

                <InputGroup.Text id='basic-addon1'>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>

          <div className='p-3 rounded border shadow text-start mt-2'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Start Value</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  placeholder='00.00'
                  value={startValue}
                  onChange={e => {
                    e.target.value
                      ? setStartValue(e.target.value)
                      : setStartValue('')
                    dispatch(Actions.setUnsavedChanged(true))
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className='p-3 rounded border shadow text-start mt-2'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>End Value</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  placeholder='00.00'
                  value={endValue}
                  onChange={e => {
                    dispatch(Actions.setUnsavedChanged(true))
                    e.target.value
                      ? setEndValue(e.target.value)
                      : setEndValue('')
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
        <Col md={4} className='m-3'>
          <div className='p-3 rounded border shadow text-start '>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Sold for Profit</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  placeholder='00.00'
                  value={profitSold}
                  onChange={e => {
                    dispatch(Actions.setUnsavedChanged(true))
                    e.target.value
                      ? setProfitSold(e.target.value)
                      : setProfitSold('')
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>

          <div className='p-3 rounded border shadow text-start mt-2'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Sold For Loss</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  placeholder='00.00'
                  value={lossSold}
                  onChange={e => {
                    dispatch(Actions.setUnsavedChanged(true))
                    e.target.value
                      ? setLossSold(e.target.value)
                      : setLossSold('')
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className='m-3 mx-auto'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={performanceSrc ?? ''}
                imgType='modelPortfolio'
                label='Model Portfolio'
                onFileUploaded={imgSrc => setPerformanceSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
