import { Button, Container, Form, Spinner, Table } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useBoolean from '../hooks/useBoolean'
import { useEffect, useState } from 'react'
import { SectorHeater } from '../_types/sector-heaters.type'
import { useDispatch } from 'react-redux'
import { HeatersService } from '../services/heaters.service'
import CreateHeaterModal from '../components/modal/create-heater.modal'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

export default function SectorHeaters (props: PageProps) {
  const [showExpired, { toggle: toggleExpired }] = useBoolean(false)
  const [showForm, { toggle: toggleShowForm }] = useBoolean(false)

  const [currentHeater, setCurrentHeater] = useState<SectorHeater | null>(null)
  const [heaters, setHeaters] = useState<SectorHeater[]>([])
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const heatersService = new HeatersService(dispatch)

  useEffect(() => {
    _getHeaters()
  }, [])

  const _getHeaters = async () => {
    setLoading(true)
    const res = await heatersService.getHeaters(
      showExpired ? 'expired' : 'active'
    )
    setHeaters(res.heaters ?? [])
    setLoading(false)
  }

  const _deleteHeater = async (id: string) => {
    if (
      window.confirm(
        'Are you sure you want to delete this heater. This action cannot be undone'
      )
    ) {
      setLoading(true)
      const res = await heatersService.deleteHeater(id)
      setHeaters(heaters.filter(h => h.id !== id))
      setLoading(false)
    }
  }
  return (
    <Container className='p-3'>
      <h1 className='text-start'>Sector Heaters</h1>
      <p className='text-start'>
        Create Sector Heaters.{' '}
        <em>
          Note: Expire heaters to include stop auto updating and associate them
          with the proper wrap.
        </em>
      </p>

      {loading && (
        <div className='text-center'>
          <Spinner animation='border' />
        </div>
      )}

      {!loading && (
        <div className='shadow p-2 border'>
          <div className='text-end my-2'>
            <Button variant='success' onClick={toggleShowForm}>
              Create Heater
            </Button>
          </div>
          <Table striped responsive hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Created</th>
                <th>Stocks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {heaters?.map(heater => (
                <tr>
                  <td>{heater.title}</td>
                  <td>
                    {new Date(
                      heater.created ?? new Date().getTime()
                    ).toLocaleDateString('en-US')}
                  </td>
                  <td>{heater.stocks?.map(s => s.symbol).join(', ')}</td>
                  <td>
                    <FaPencilAlt
                      className='pointer'
                      onClick={() => {
                        setCurrentHeater(heater)
                        toggleShowForm()
                      }}
                    />
                    <FaTrash
                      className='ms-2 pointer text-danger'
                      onClick={() => _deleteHeater(heater.id ?? '')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {showForm && (
        <CreateHeaterModal
          heater={currentHeater}
          show={showForm}
          onClose={() => {
            toggleShowForm()
            setCurrentHeater(null)
          }}
          onRefresh={_getHeaters}
        />
      )}
    </Container>
  )
}
