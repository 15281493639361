import { Button, Spinner } from 'react-bootstrap'

interface LoadingBtnProps {
  text: string
  isLoading: boolean
  disabled: boolean
  className?: string
  onClick?: (...args: any[]) => void
  type: 'button' | 'submit' | 'reset' | undefined
  variant: 'primary' | 'secondary' | 'dark' | 'light' | 'text' | 'warning'
}
export default function LoadingBtn (props: LoadingBtnProps) {
  return (
    <Button
      variant={props.variant}
      onClick={props.onClick}
      type={props.type}
      className={`${props.className}`}
      disabled={props.disabled || props.isLoading}
    >
      {props.text}{' '}
      {props.isLoading && <Spinner animation='border' className='ms-1' />}
    </Button>
  )
}
