import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import DragFileInput from '../../drag-file-input'
import LoadingBtn from '../../loading-btn'
import { Position } from '../../../_types/daily-wrap.type'
import { FaTrash } from 'react-icons/fa'
import WrapDiscardBtn from '../wrap-discard-button'

export default function WrapPositions () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [loading, setLoading] = useState(false)

  const [flicker, setFlicker] = useState(true)
  const [copyLoading, setCopyLoading] = useState(false)
  const [positions, setPositions] = useState<Position[]>([])
  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)

  useEffect(() => {
    if (currentWrap) {
      setPositions(JSON.parse(JSON.stringify(currentWrap?.positions ?? [])))
    }
  }, [currentWrap])

  const _onSubmit = async () => {
    const tempPositions = JSON.parse(JSON.stringify(positions))

    for (const p of tempPositions) {
      if (!p.ticker || !p.shares) {
        dispatch(
          Actions.setError(`Ticker and shares are required on all fields`)
        )
        return
      }

      try {
        p.shares = Number(p.shares)
      } catch (e) {
        dispatch(
          Actions.setError(`Ticker and shares are required on all fields`)
        )
        return
      }
    }
    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      positions: tempPositions
    })

    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
    } else {
      dispatch(Actions.setError('An Unknown error occurred'))
    }

    setLoading(false)
  }

  const _editPosition = (index: number, field: string, value: any) => {
    const tempPositions = JSON.parse(JSON.stringify(positions))
    if (field === 'ticker') {
      tempPositions[index][`${field}`] = value.replace(/ /g, '').toUpperCase()
    } else {
      tempPositions[index][`${field}`] = value
    }
    setPositions(tempPositions)
    dispatch(Actions.setUnsavedChanged(true))
  }

  const _addNew = () => {
    let tempPositions : Position[] = JSON.parse(JSON.stringify(positions ?? [])) ?? []

    tempPositions.unshift({
      ticker: '',
      descr: '',
      commentary: '',
      newPosition: false,
      shares: '',
      chartSrc: ''
    })
    dispatch(Actions.setUnsavedChanged(true))
    setPositions(tempPositions)
  }

  const _deletePosition = (index: number) => {
    let tempPositions = JSON.parse(JSON.stringify(positions))
    tempPositions = tempPositions.filter((p: any, i: number) => i !== index)
    setPositions(tempPositions)
    dispatch(Actions.setUnsavedChanged(true))
  }

  const _copyFromYesterday = async () => {
    setCopyLoading(true)
    const resp = await wrapService.getPreviousWrap(QueryHelper.get('id') ?? '')
    if (resp.wrap) {
      setPositions(resp.wrap.positions ?? [])
      const response = await wrapService.updateWrap(currentWrap?.id ?? '', {
        positions: resp.wrap.positions ?? []
      })
      if (response.wrap) {
        dispatch(Actions.setCurrentWrap(response.wrap))
        dispatch(
          Actions.setToast(
            'Copied and saved from yesterday ✅',
            'Your changes have been saved'
          )
        )
      }
    }
    setCopyLoading(false)
  }

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Positions</h4>
          <p className='text-start'> Descriptions are automatically generated for valid tickers</p>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            className='mx-2'
            disabled={copyLoading}
            isLoading={copyLoading}
            onClick={_copyFromYesterday}
            text='Copy From Last'
            variant='secondary'
            type='button'
          />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />
      <div className='text-end'>
        <Button variant='success' onClick={_addNew}>
          Add +
        </Button>
      </div>

      <Row>
        {positions?.map((p, index) => (
          <Col md={6} key={index} className='p-2'>
            <div className='p-3 rounded border shadow text-start'>
              <Row>
                <Col>
                  <Form.Group className='text-start'>
                    <Form.Label>Ticker</Form.Label>
                    <Form.Control
                      placeholder='ticker'
                      value={p.ticker ?? ''}
                      onChange={e =>
                        _editPosition(index, 'ticker', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group className='text-start mt-2'>
                    <Form.Label>Shares</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='shares'
                      value={p.shares ?? ''}
                      onChange={e =>
                        _editPosition(index, 'shares', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group className='text-start mt-2'>
                    <Form.Label>Commentary</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      placeholder='commentary'
                      value={p.commentary ?? ''}
                      onChange={e =>
                        _editPosition(index, 'commentary', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group className='text-start mt-2'>
                    <Form.Label>Is New Position?</Form.Label>

                    <Form.Check // prettier-ignore
                      type='switch'
                      id='custom-switch'
                      onChange={e =>
                        _editPosition(
                          index,
                          'newPosition',
                          p.newPosition ? false : true
                        )
                      }
                      checked={p.newPosition}
                      label={p.newPosition ? 'YES' : 'NO'}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {flicker && (
                    <DragFileInput
                      initialSrc={p.chartSrc ?? ''}
                      imgType={`position-${index}`}
                      label='Chart'
                      onFileUploaded={imgSrc =>
                        _editPosition(index, 'chartSrc', imgSrc ?? '')
                      }
                    />
                  )}
                </Col>
              </Row>
              <hr />
              <div className='text-center'>
                <Button variant='danger' onClick={() => _deletePosition(index)}>
                  Delete Position <FaTrash className='ms-2' />
                </Button>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
