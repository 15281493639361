import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'
import { SectorHeater } from '../_types/sector-heaters.type'
import { CarnivoreEvent } from '../_types/carnivore-event.type'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/content`,
  withCredentials: false
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class ContentService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async createContent (title: string, eventType: string) {
    return axiosInstance
      .post('/', {
        title,
        type: eventType
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async getById (id: string) {
    return axiosInstance
      .get(`/${id}`)
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
  async updateContent (id: string, content: any) {
    return axiosInstance
      .put(`/${id}`, {
        content
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
  //   async getPresignedUploadURL (
  //     name: string
  //   ): Promise<
  //     | { url: string; key: string }
  //     | { errMSg: string; url?: string; key?: string }
  //   > {
  //     return axiosInstance
  //       .post('/presign-upload', {
  //         name
  //       })
  //       .then(res => res.data.data)
  //       .catch(e => apiErrorHandler(e, this.dispatch))
  //   }
}
