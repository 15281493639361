import { Button, ListGroup } from 'react-bootstrap'
import WrapNavItem from './wrap-nav-item'
import { WrapBuilderPage } from '../../_constants/enums/wrap-builder-pages.enum'
import { useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'

export default function WrapNav () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)
  return (
    <div>
      <h3 className='text-start'>Sections</h3>
      <ListGroup>
        <WrapNavItem
          label='Metadata'
          pageKey={WrapBuilderPage.meta}
          disabled={false}
          complete={
            currentWrap?.formattedDate !== (null || undefined) &&
            currentWrap?.tag !== (null || undefined) &&
            currentWrap?.id !== (null || undefined)
          }
        />
        <WrapNavItem
          label='Trader Z'
          pageKey={WrapBuilderPage.traderZ}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.traderZThoughts !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Performance'
          pageKey={WrapBuilderPage.performance}
          disabled={currentWrap?.id === (null || undefined)}
          complete={currentWrap?.currentYTD !== (null || undefined)}
        />

        <WrapNavItem
          label='Portfolio'
          pageKey={WrapBuilderPage.portfolio}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.images?.percentPortfolioSrc !== (null || undefined) &&
            currentWrap?.images?.portfolioPerformanceSrc !==
              (null || undefined) &&
            currentWrap?.images?.modelPortfolioSwingSrc !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Market Headlines'
          pageKey={WrapBuilderPage.headlines}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.marketHeadlines !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Index'
          pageKey={WrapBuilderPage.index}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.dowPoints !== (null || undefined) &&
            currentWrap?.nasdaqPoints !== (null || undefined) &&
            currentWrap?.images?.spxIndexSrc !== (null || undefined) &&
            currentWrap?.images?.compqIndexSrc !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Sectors'
          pageKey={WrapBuilderPage.sectors}
          disabled={currentWrap?.id === (null || undefined)}
          complete={currentWrap?.images?.heatMapSrc !== (null || undefined)}
        />

        <WrapNavItem
          label='Specialty Trades'
          pageKey={WrapBuilderPage.specialtyTrades}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.specialtyTrades !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Positions'
          pageKey={WrapBuilderPage.positions}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.positions?.length !== 0 &&
            currentWrap?.positions !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Trading Summary'
          pageKey={WrapBuilderPage.summary}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.tradingSummary !== (null || undefined)
          }
        />
        <WrapNavItem
          label='AM Session'
          pageKey={WrapBuilderPage.amSession}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.amSession !== (null || undefined)
          }
        />

        <WrapNavItem
          label='PM Session'
          pageKey={WrapBuilderPage.pmSession}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.pmSession !== (null || undefined)
          }
        />

        <WrapNavItem
          label='Commentary'
          pageKey={WrapBuilderPage.commentary}
          disabled={currentWrap?.id === (null || undefined)}
          complete={
            currentWrap?.contentBlocks?.commentary !== (null || undefined)
          }
        />
        <WrapNavItem
          label='Babes'
          pageKey={WrapBuilderPage.babes}
          disabled={currentWrap?.id === (null || undefined)}
          complete={currentWrap?.contentBlocks?.babes !== (null || undefined)}
        />
        <WrapNavItem
          label='Bonus'
          pageKey={WrapBuilderPage.bonus}
          disabled={currentWrap?.id === (null || undefined)}
          complete={currentWrap?.contentBlocks?.bonus !== (null || undefined)}
        />

        <WrapNavItem
          label='Publish'
          pageKey={WrapBuilderPage.publish}
          disabled={currentWrap?.id === (null || undefined)}
          complete={currentWrap?.status === 'published'}
        />
      </ListGroup>
    </div>
  )
}
