import { Button, Container, Spinner, Table } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import CreateUserModal from '../components/modal/createuser-modal'
import { useDispatch } from 'react-redux'
import { ConsoleUserService } from '../services/consoleuser.service'
import { ConsoleUser } from '../_types/console-user.type'

export default function Team (props: PageProps) {
  useDocumentTitle(props.title)

  const [showNew, setShowNew] = useState(false)
  const [loading, setLoading] = useState(true)
  const [consoleUsers, setConsoleUsers] = useState<ConsoleUser[]>([])
  const dispatch = useDispatch()
  const consoleUserService = new ConsoleUserService(dispatch)

  useEffect(() => {
    _getUsers()
  }, [])
  const _getUsers = async () => {
    setLoading(true)
    const resp = await consoleUserService.listUsers()
    if (resp.consoleUsers) {
      setConsoleUsers(resp.consoleUsers)
    }
    setLoading(false)
  }

  return (
    <Container className='p-3'>
      <h1 className='text-start'>Teammates</h1>
      <p className='text-start'>
        Add, remove, or manage Carnivore Team members
      </p>

      <div className='text-end my-2'>
        <Button variant='success' onClick={() => setShowNew(true)}>
          Create +
        </Button>
      </div>

      <Table striped bordered hover responsive className=''>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {consoleUsers?.map(u => (
            <tr key={u.id}>
              <td>{u.email}</td>
              <td>{u.name}</td>
              <td>{u.role}</td>
              <td></td>

            </tr>
          ))}
        </tbody>
      </Table>
    <div className="text-center">
    {loading && <Spinner animation='border' />}

    </div>

      {showNew && (
        <CreateUserModal show={showNew} onClose={() => setShowNew(false)} />
      )}
    </Container>
  )
}
