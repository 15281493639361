import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { StyleHelper } from "../helpers/styler-helper";

interface CTCardProps {
    children: ReactNode,
    title: string,
    icon?: string,
    actionLabel?: string
    onAction?: () => void
}


export default function CTCard(props: CTCardProps) {

    const { children, title, icon, actionLabel, onAction } = props


    return (
        <Paper elevation={3} >
            <Box p={2}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={1}>
                    <Typography variant="subtitle1" fontWeight={'bold'}>{title}</Typography>
                    {onAction && actionLabel && <Button variant="outlined" onClick={onAction}>{actionLabel}</Button>}

                </Box>


                <Divider />
                <StyleHelper.VerticalSpace />
                {children}
            </Box>
        </Paper>
    )
}