import { Container } from 'react-bootstrap'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { StyleHelper } from '../helpers/styler-helper'
import { useEffect, useState } from 'react'
import { CommentService } from '../services/comment.service'
import { useDispatch } from 'react-redux'
import { CarnivoreComment } from '../_types/comment.type'
import { Formatters } from '../helpers/formatters'
import { BugReport, Chat, Lightbulb } from '@mui/icons-material'

export default function FeedbackPage (props: PageProps) {
  useDocumentTitle(props.title)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [comments, setComments] = useState<CarnivoreComment[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalDocs, setTotalDocs] = useState(0)
  const [refreshing, setRefreshing] = useState(true)

  const dispatch = useDispatch()
  const commentService = new CommentService(dispatch)

  useEffect(() => {
    _fetchComments(1)
  }, [])

  const _fetchComments = async (p: number) => {
    setPage(p)

    const results = await commentService.queryComments(p)

    if (results?.docs) {
      setComments(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
      setTotalDocs(results.totalDocs)
    }
    setRefreshing(false)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const _getIcon = (comment: CarnivoreComment) => {
    if (comment.type === 'bug') {
      return <BugReport />
    } else if (comment.type === 'feature') {
      return <Lightbulb />
    } else {
      return <Chat />
    }
  }
  return (
    <Container className='p-3'>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <Box>
          <Typography variant='h2' textAlign={'start'}>
            User Feedback
          </Typography>
          <Typography variant='body1' textAlign={'start'}>
            View user's feedback
          </Typography>
        </Box>
      </Box>

      <Divider />
      <StyleHelper.VerticalSpace />
      {loading && <CircularProgress />}

      {!loading && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='comment table'>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Type</TableCell>

                  <TableCell>Content</TableCell>
                  <TableCell>Submitted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comments?.map(comment => (
                  <TableRow
                    key={comment.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f2f2f2'
                      }
                    }}
                  >
                    <TableCell>{comment.user?.email ?? comment.user}</TableCell>
                    <TableCell>
                      <Chip
                        label={Formatters.removeHyphensAndUppercase(
                          comment.type
                        )}
                        icon={_getIcon(comment)}
                      />
                    </TableCell>
                    <TableCell>{comment.message}</TableCell>
                    <TableCell>
                      {new Date(comment.createdAt).toLocaleString('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short'
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <StyleHelper.VerticalSpace />
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event: unknown, page: number) => {
                _fetchComments(page)
              }}
              variant='outlined'
              shape='rounded'
            />
          </Stack>
        </>
      )}
    </Container>
  )
}
