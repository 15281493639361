import { useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import PAIModalProps from '../../_types/modal-props.type'
import CarnivoreModalProps from '../../_types/modal-props.type'

interface ConfirmModalProps extends CarnivoreModalProps {
  onDismiss: () => void
  onConfirm: () => Promise<void>
  header: string
  body: string
}

export default function ConfirmModal (props: ConfirmModalProps) {
  return (
    <Modal show={props.show} size='lg' centered onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant='link' onClick={props.onClose}>
          No
        </Button>
        <Button
          variant='primary'
          onClick={async () => {
            await props.onConfirm()
            props.onClose()
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
