import { Dispatch } from 'redux'
import Actions from '../redux/actions'

export default function apiErrorHandler (err: any, dispatch: Dispatch) {
  console.log(err)
  if (err?.response?.status === 403) {
    if (window.location.href !== '/login')
      window.location.href = '/index.html#/login?expired=true'
    return {}
  }
  if (err?.response?.data?.message) {
    dispatch(Actions.setError(err.response?.data?.message))
    return { errMsg: err.response?.data?.message }
  }

  dispatch(Actions.setError(String(err.message)))

  return { errMsg: err.message }
}
