import { useCallback, useState } from 'react'

// Hook return type
interface UseBooleanActions {
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
}

// Hook definition
function useBoolean (
  defaultValue: boolean = false
): [boolean, UseBooleanActions] {
  const [value, setValue] = useState<boolean>(defaultValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue(v => !v), [])

  return [value, { setTrue, setFalse, toggle }]
}

export default useBoolean
