import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

const LoadingButtonMUI: React.FC<LoadingButtonProps> = ({
  loading = false,
  children,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps} disabled={loading || buttonProps.disabled}>
      {children}
      {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
    </Button>
  )
}

export default LoadingButtonMUI
