import { FormEvent, useState } from 'react'
import {
  Modal,
  Form,
  Button,
  Spinner,
  InputGroup,
  Alert,
  Row
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import PAIModalProps from '../../_types/modal-props.type'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import LoadingBtn from '../loading-btn'
import ErrorBar from '../error-bar'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { ConsoleUserService } from '../../services/consoleuser.service'
import Actions from '../../redux/actions'

export default function ChangePassModal (props: CarnivoreModalProps) {
  const [loading, setLoading] = useState(false)
  const error = useSelector((state: CarnivoreState) => state.errMsg)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showNew, setShowNew] = useState(false)
  const [showOld, setShowOld] = useState(false)

  const dispatch = useDispatch()
  const consoleUserService = new ConsoleUserService(dispatch)

  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const resp = await consoleUserService.changePassword(
      oldPassword,
      newPassword
    )
    setLoading(false)
    if (resp.user) {
      dispatch(Actions.setUser(resp.user))
      dispatch(Actions.setToast(`Password Updated ✅`, 'On Next Login, this password will be your password'))
      props.onClose()
    }
  }
  return (
    <Modal show={props.show} centered>
      <Modal.Header>
        <Modal.Title>Password Expired</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant='danger my-3'>{error}</Alert>}
        {/* <ErrorBar />
        <br /> */}
        <p>You Must change your password before continuing</p>
        <Form onSubmit={_onSubmit}>
          <Form.Group className='mt-3 text-start'>
            <Form.Label className='text-start'>Old Password</Form.Label>

            <InputGroup className='mb-3'>
              <Form.Control
                required
                type={!showOld ? 'password' : 'text'}
                placeholder='Old Password'
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
              />
              <InputGroup.Text className='bg-dark rounded-end'>
                {showOld ? (
                  <AiFillEye
                    className='pointer text-white'
                    onClick={() => setShowOld(!setShowOld)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className='pointer text-white'
                    onClick={() => setShowOld(!setShowOld)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <hr className='my-2' />
          <Form.Group className='mt-3 text-start'>
            <Form.Label className='text-start'>New Password</Form.Label>

            <InputGroup className='mb-3'>
              <Form.Control
                required
                type={!showNew ? 'password' : 'text'}
                placeholder='New Password'
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
              <InputGroup.Text className='bg-dark rounded-end'>
                {showNew ? (
                  <AiFillEye
                    className='pointer text-white'
                    onClick={() => setShowNew(!showNew)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className='pointer text-white'
                    onClick={() => setShowNew(!showNew)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className='mt-3 text-start'>
            <Form.Label className='text-start'>Confirm Password</Form.Label>

            <InputGroup className='mb-3'>
              <Form.Control
                required
                type={!showNew ? 'password' : 'text'}
                placeholder='Confirm New Password'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <InputGroup.Text className='bg-dark rounded-end'>
                {showNew ? (
                  <AiFillEye
                    className='pointer text-white'
                    onClick={() => setShowNew(!showNew)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className='pointer text-white'
                    onClick={() => setShowNew(!showNew)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className='text-center'>
            <LoadingBtn
              text='Submit'
              variant='primary'
              disabled={loading}
              isLoading={loading}
              type='submit'
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
