import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import WrapDiscardBtn from '../wrap-discard-button'
import LoadingBtn from '../../loading-btn'
import { useEffect, useState } from 'react'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import ConfirmModal from '../../modal/confirm-modal'

export default function WrapPublish () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [status, setStatus] = useState('draft')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const wrapService = new WrapService(dispatch)

  const [showNotify, setShowNotify] = useState(false)
  const _onSubmit = async () => {
    let shouldShowAlert = false
    if (currentWrap.status === 'draft' && status === 'published') {
      shouldShowAlert = true
    }

    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      status
    })
    if (resp.wrap) {
      dispatch(
        Actions.setToast(`Wrap is status changed to ${status} ✅`, 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
    } else {
      dispatch(Actions.setError('An Unknown error occurred'))
    }
    setLoading(false)

    if (shouldShowAlert) {
      setShowNotify(true)
    }
  }

  const _sendAlert = async () => {}

  useEffect(() => {
    if (currentWrap) {
      setStatus(currentWrap.status ?? 'draft')
    }
  }, [currentWrap])

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Publish Status</h4>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />
      <Row className=' flex-wrap'>
        <Col md={4} className='m-3'>
          <div className='p-3 rounded border shadow text-start'>
            <Form.Label>Published Status</Form.Label>
            <Form.Select
              value={status}
              onChange={e => {
                dispatch(Actions.setUnsavedChanged(true))
                setStatus(e.target.value)
              }}
            >
              <option value='draft'>Draft</option>
              <option value='published'>Published</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <ConfirmModal
        header='Send Wrap Published Email/Text'
        body='Would you like to send an alert that the wrap is published to active carnivore email/SMS list?'
        show={showNotify}
        onClose={() => setShowNotify(false)}
        onConfirm={_sendAlert}
        onDismiss={() => {}}
      />
    </Container>
  )
}
