import { useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { useEffect, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
import { ProgressBar } from 'react-bootstrap'

export default function WrapProgress () {
  const wrap = useSelector((state: CarnivoreState) => state.currentWrap)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (wrap) {
      _calcScore(wrap)
    }
  }, [wrap])

  const _calcScore = (currentWrap: DailyWrap) => {
    let score = 0
    if (
      currentWrap?.formattedDate !== (null || undefined) &&
      currentWrap?.tag !== (null || undefined) &&
      currentWrap?.id !== (null || undefined)
    ) {
      score++
    }

    if (currentWrap?.contentBlocks?.traderZThoughts !== (null || undefined)) {
      score++
    }

    if (currentWrap?.currentYTD !== (null || undefined)) {
      score++
    }

    if (
      currentWrap?.images?.percentPortfolioSrc !== (null || undefined) &&
      currentWrap?.images?.portfolioPerformanceSrc !== (null || undefined) &&
      currentWrap?.images?.modelPortfolioSwingSrc !== (null || undefined)
    ) {
      score++
    }

    if (currentWrap?.contentBlocks?.marketHeadlines !== (null || undefined)) {
      score++
    }

    if (
      currentWrap?.dowPoints !== (null || undefined) &&
      currentWrap?.nasdaqPoints !== (null || undefined) &&
      currentWrap?.images?.spxIndexSrc !== (null || undefined) &&
      currentWrap?.images?.compqIndexSrc !== (null || undefined)
    ) {
      score++
    }

    if (currentWrap?.images?.heatMapSrc !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.specialtyTrades !== (null || undefined)) {
      score++
    }

    if (
      currentWrap?.positions?.length !== 0 &&
      currentWrap?.positions !== (null || undefined)
    ) {
      score++
    }

    if (currentWrap?.contentBlocks?.tradingSummary !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.amSession !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.pmSession !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.commentary !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.babes !== (null || undefined)) {
      score++
    }

    if (currentWrap?.contentBlocks?.bonus !== (null || undefined)) {
      score++
    }

    setProgress((score / 15 * 100))
  }

  return (
    <ProgressBar
      now={progress}
      className='my-2'
      label={`${progress}%`}
      visuallyHidden
    />
  )
}
