import React, { useState, useEffect } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../_types/carnivore-state.type'
import Actions from '../redux/actions'

export default function ErrorBar () {
  const [show, setShow] = useState(false)

  const errMsg = useSelector((state: CarnivoreState) => state.errMsg)
  const dispatch = useDispatch()
  useEffect(() => {
    if (errMsg && errMsg !== '') {
      setShow(true)
      const timer = setTimeout(() => {
        dispatch(Actions.setError(''))
      }, 5000)
    }else{
      setShow(false)
    }
  }, [errMsg])



  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className={`top-bar bg-danger ${show ? 'show' : ''}`}>
      {errMsg}
      <AiFillCloseCircle
        className='text-white pointer close-button mt-1'
        onClick={handleClose}
      />
    </div>
  )
}
