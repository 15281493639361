import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { PageProps } from '../../_types/page.props.interface'
import useDocumentTitle from '../../helpers/title.helper'
import { useNavigate } from 'react-router-dom'
import { FaBell, FaCheckCircle, FaCopy, FaPen, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Actions from '../../redux/actions'
import { useEffect, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
import { WrapService } from '../../services/wrap.service'
import { FaPencilAlt } from 'react-icons/fa'
import { MdDrafts } from 'react-icons/md'
import { WrapBuilderPage } from '../../_constants/enums/wrap-builder-pages.enum'

export default function DailyWrapPage (props: PageProps) {
  useDocumentTitle(props.title)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalWraps, setTotalWraps] = useState(0)
  const [cloningId, setCloningId] = useState('')
  const [isCloning, setIsCloning] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const [wraps, setWraps] = useState<DailyWrap[]>([])

  const [tableLoading, setTableLoading] = useState(true)

  const wrapService = new WrapService(dispatch)

  const _getWraps = async (page: number) => {
    setTableLoading(true)
    const resp = await wrapService.queryWraps(page)
    setHasNextPage(resp.hasNextPage)
    setHasPrevPage(resp.hasPrevPage)
    setTotalWraps(resp.totalDocs)
    setWraps(resp.docs)
    setTableLoading(false)
    setPage(page)
  }

  useEffect(() => {
    _getWraps(page)
  }, [])

  const _getTodayFormatted = () => {
    let today = new Date()
    let year = today.getFullYear()
    let month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-indexed, so we add 1
    let day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const _cloneWrap = async (id: string) => {
    setCloningId(id)
    const cloneResp = await wrapService.cloneWrap(id)
    if (cloneResp.wrap) {
      dispatch(Actions.setCurrentWrap(cloneResp.wrap))
    }
    setCloningId('')
    navigate(`/daily-wrap/builder?id=${cloneResp.wrap.id ?? ''}`)
    dispatch(Actions.setToast("Successfully cloned wrap with today's date", 'you are now editing the new wrap'))
  }

  const _deleteById = async (id: string) => {
    if (window.confirm('Are you sure. This delete action cannot be undone')) {
      setDeleteId(id)
      await wrapService.deleteWrapById(id)
      setDeleteId('')
      _getWraps(1)
    }
  }

  return (
    <Container className='p-3'>
      <h1 className='text-start'>Daily Wrap</h1>
      <p className='text-start'>Edit, View, and Create New Daily Wraps</p>

      <Row className='justify-content-center'>
        <Col className='p-3 text-end' md={4}>
          <div className='border ounded shadow'>
            <div className='py-1 bg-warning rounded-top' />
            <div className='p-3'>
              <h5 className='text-muted'>Alerting</h5>
              <Button variant='danger me-2 ' onClick={() => window.alert('COMING SOON')}>
                Z Section Alert <strong className='ms-2'>Z</strong>
              </Button>

              <Button variant='warning' onClick={() => window.alert('COMING SOON')}>
                Published Alert <FaBell className='ms-2 my-auto' />
              </Button>
            </div>
          </div>
        </Col>

        <Col className='p-3 text-end' md={4}>
          <div className='border rounded shadow'>
            <div className='py-1 bg-success rounded-top ' />
            <div className='p-3'>
              <h5 className='text-muted'>Quick Actions</h5>
              {/* <Button className='me-2' variant='danger'>
                Clone From Yesterday
              </Button> */}
              <Button
                variant='success'
                onClick={() => {
                  dispatch(Actions.setCurrentWrap(null))
                  dispatch(Actions.setBuilderPage(WrapBuilderPage.meta))
                  navigate('/daily-wrap/builder')
                }}
              >
                Create Blank Wrap
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Table
        responsive
        striped
        hover
        bordered
        className='bg-white p-1 rounded border shadow'
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>Tag</th>
            <th>Status</th>
            <th>Last Edit</th>
          </tr>
        </thead>
        <tbody className='text-start'>
          {wraps?.map(wrap => (
            <tr key={wrap.id}>
              <td>
                {wrap.formattedDate}{' '}
                {_getTodayFormatted() === wrap.formattedDate && (
                  <strong className='ms-2 text-primary'>(TODAY'S)</strong>
                )}
              </td>
              <td>{wrap.tag}</td>
              <td>
                {wrap.status}{' '}
                {wrap.status === 'published' ? (
                  <FaCheckCircle className='text-success ms-2' />
                ) : (
                  <FaPencilAlt className='text-secondary ms-2' />
                )}{' '}
              </td>
              <td>
                {new Date(wrap.updatedAt ?? '').toLocaleString('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short'
                })}
              </td>
              <td>
                <FaPencilAlt
                  title='edit'
                  className='pointer mx-2'
                  onClick={() => {
                    dispatch(Actions.setCurrentWrap(wrap))
                    dispatch(Actions.setBuilderPage(WrapBuilderPage.meta))

                    navigate(`/daily-wrap/builder?id=${wrap.id}`)
                  }}
                />

                {cloningId === wrap.id ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <FaCopy
                    title='clone'
                    className='pointer mx-2'
                    onClick={
                      cloningId !== ''
                        ? () => null
                        : () => _cloneWrap(wrap.id ?? '')
                    }
                  />
                )}

                {deleteId === wrap.id ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <FaTrash
                    title='delete'
                    className='pointer mx-2 text-danger'
                    onClick={
                      deleteId !== ''
                        ? () => null
                        : () => {
                            _deleteById(wrap.id ?? '')
                          }
                    }
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
