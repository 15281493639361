import { Box, Chip, Drawer, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import { CTUser } from "../../_types/user.type"
import { Close } from "@mui/icons-material"
import { StyleHelper } from "../../helpers/styler-helper"
import CTCard from "../ct-card"
import { ReactNode, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { UserMockService } from "../../services/mock.service"
import { Formatters } from "../../helpers/formatters"

interface UserDrawerProps {
    isOpen: boolean,
    user?: CTUser,
    updateUser : (user : CTUser) => void
    closeDrawer: () => void
}

export default function UserDrawer(props: UserDrawerProps) {
    const { isOpen, closeDrawer, updateUser } = props

    const [user, setUser] = useState<CTUser | undefined>(props.user)
    const [subscriptionInfo, setSubscriptionInfo] = useState<null | any>(null)

    const [subLoading, setSubLoading] = useState(true)
    const [klaviyoLoading, setKlaviyoLoading] = useState(false)

    const dispatch = useDispatch()
    const mockUserService = new UserMockService(dispatch)


    useEffect(() => {
        if (props.user) {
            _fetchSub(props.user.id)
            setUser(props.user)
        }

    }, [])


    const _checkKlaviyo = async () => {
        setKlaviyoLoading(true)
        const res = await mockUserService.getKlaviyoStatus(user?.id ?? "")
        if (res.user) {
            setUser(res.user)
            updateUser(res.user)
        }
        setKlaviyoLoading(false)
    }

    const _fetchSub = async (userId: string) => {
        setSubLoading(true)
        if (user?.paymentSource === 'woo') {
            const res = await mockUserService.getWooSubscription(userId)
            if (res.subscription) {
                setSubscriptionInfo(res.subscription)
            }
        }
        setSubLoading(false)

    }
    return (
        <Drawer
            anchor='right'
            open={isOpen}
            sx={{
                width: '40%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '40%',
                    boxSizing: 'border-box'
                }
            }}
            onClose={closeDrawer}
        >
            <Box
                sx={{
                    p: 3
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 2
                    }}
                >
                    <Typography variant="h5">{user?.email}</Typography>
                    <IconButton onClick={closeDrawer}>
                        <Close />
                    </IconButton>
                </Box>

                <StyleHelper.VerticalSpace />
                <CTCard title="General">
                    <UserInfoRow label="First Name" value={user?.fName} />
                    <UserInfoRow label="Last Name" value={user?.lName} />
                    <UserInfoRow label="Email" value={user?.email} />
                    <UserInfoRow label="Phone Number" value={user?.phoneNumber} />

                </CTCard>
                <StyleHelper.VerticalSpace />
                {subLoading && <Skeleton width={"100%"} height={450} />}
                {user?.paymentSource === 'woo' && !subLoading && <CTCard title="Subscription Info" actionLabel="manage" onAction={() => {

                    window.open(
                        `https://carnivoretrading.com/wp-admin/admin.php?page=wc-orders--shop_subscription&action=edit&id=${user.subID}`,
                        '_blank'
                    )
                }}>
                    <UserInfoRow label="Source" value={subscriptionInfo?.status?.toUpperCase()} />
                    <UserInfoRow label="Source" value={"WooCommerce"} />
                    <UserInfoRow label="Plan" value={subscriptionInfo?.billing_period === 'month'
                        ? 'Monthly'
                        : 'Annual'} />
                    <UserInfoRow label={subscriptionInfo?.status === 'active'
                        ? 'Next Billing Date'
                        : 'Subscription Ends'} value={subscriptionInfo?.next_payment_date_gmt
                            ? new Date(
                                subscriptionInfo?.next_payment_date_gmt
                            ).toLocaleDateString('en-US', { dateStyle: 'medium' })
                            : 'N/A'} />
                    <UserInfoRow label="Next Payment Amount" value={Formatters.formatMoney(
                        subscriptionInfo?.total ? Number(subscriptionInfo?.total) : 0
                    )} />

                    <UserInfoRow label="Coupons" value={<Stack display={'row'} spacing={2}>
                        {subscriptionInfo?.coupon_lines?.length > 0 ? subscriptionInfo?.coupon_lines?.map((s: any) => s.code).map((c: string) => (
                            <Box><Chip label={c} sx={{ alignSelf: 'auto' }} /></Box>
                        )) : 'n/a'}
                    </Stack>} />

                </CTCard>}

                <StyleHelper.VerticalSpace />
                {klaviyoLoading && <Skeleton width={"100%"} height={450} />}

                {!klaviyoLoading && <CTCard title="Notifications" actionLabel="Reload" onAction={_checkKlaviyo}>
                    <UserInfoRow label="Browser Enabled" value={user?.browserNotification ? 'YES' : 'No'} />
                    <UserInfoRow label="Last Klaviyo Check" value={user?.klaviyoStatus?.lastCheck ? new Date(user?.klaviyoStatus?.lastCheck ?? "").toLocaleDateString('en-US') : null} />
                    <UserInfoRow label="On Email List" value={user?.klaviyoStatus?.isOnEmailList != undefined ? (user?.klaviyoStatus?.isOnEmailList ? 'YES' : 'NO') : 'unknown'} />
                    <UserInfoRow label="On SMS List" value={user?.klaviyoStatus?.isOnSMSList != undefined ? (user?.klaviyoStatus?.isOnSMSList ? 'YES' : 'NO') : 'unknown'} />
                    <UserInfoRow label="Email Subscribed" value={user?.klaviyoStatus?.isEmailSubscribed != undefined ? (user?.klaviyoStatus?.isEmailSubscribed ? 'YES' : 'NO') : 'unknown'} />
                    <UserInfoRow label="SMS Subscribed" value={user?.klaviyoStatus?.isSMSSubscribed != undefined ? (user?.klaviyoStatus?.isSMSSubscribed ? 'YES' : 'NO') : 'unknown'} />

                </CTCard>}
                <StyleHelper.VerticalSpace />

                <CTCard title="Meta">
                    <UserInfoRow label="Joined" value={new Date(user?.created ?? "").toLocaleDateString('en-US')} />
                    <UserInfoRow label="Last Seen" value={user?.lastSeen ? new Date(user?.created ?? "").toLocaleDateString('en-US') : null} />
                    <UserInfoRow label="Email Verified" value={user?.emailVerified ? 'Yes' : 'No'} />
                    <UserInfoRow label="Estimated Account Balance" value={user?.accountBalance} />
                    <UserInfoRow label="Company" value={user?.company} />
                    <UserInfoRow label="Profession" value={user?.profession} />
                </CTCard>
                <StyleHelper.VerticalSpace />
                <CTCard title="Address">
                    <UserInfoRow label="Street" value={user?.address?.line1} />
                    <UserInfoRow label="Apt" value={user?.address?.line2} />
                    <UserInfoRow label="City" value={user?.address?.city} />
                    <UserInfoRow label="State" value={user?.address?.state} />
                    <UserInfoRow label="Zip" value={user?.address?.postal_code} />
                    <UserInfoRow label="Country" value={user?.address?.country} />
                </CTCard>
            </Box>

        </Drawer>
    )
}


interface UserRowProps {
    label: string,
    value: ReactNode | string | undefined
}


function UserInfoRow(props: UserRowProps) {

    return <Box display={'flex'} justifyContent={'space-between'}>
        <Box style={{ flex: 1 }}>
            <Typography variant="subtitle2" textAlign={'right'}>{props.label}:</Typography>
        </Box>
        <StyleHelper.HorizontalSpace />
        <Box style={{ flex: 2 }}>
            {(typeof props.value === 'string' || !props.value) ? <Typography variant="body2" fontWeight={'300'}>{props.value ?? "n/a"}</Typography> : props.value}
        </Box>
    </Box>
}