import { useState } from 'react'
import FullScreenImgModal from './modal/fullscreen-img.modal'

interface FullScreenImgProps {
  imgSrc: string
}
export default function FullScreenImg (props: FullScreenImgProps) {
  const [showFull, setShowFull] = useState(false)
  return (
    <>
      <img
        alt='placeholder'
        src={props.imgSrc}
        className='pointer img-fluid'
        onClick={() => setShowFull(true)}
      />
      <FullScreenImgModal
        onClose={() => setShowFull(false)}
        imgSrc={props.imgSrc}
        show={showFull}
      />
    </>
  )
}
