import { useDispatch } from 'react-redux'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { AppUserService } from '../services/appuser.service'
import { FormEvent, useEffect, useState } from 'react'
import { AppUser } from '../_types/app-user.type'
import {
  Button,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import NumberCard from '../components/number-card'
import {
  AiFillCloseCircle,
  AiFillCheckCircle,
  AiFillApple,
  AiFillAndroid
} from 'react-icons/ai'
import { PiFlagBannerFill } from 'react-icons/pi'
import { MdBlock } from 'react-icons/md'

import { FaWordpress } from 'react-icons/fa'

export default function AppUsers (props: PageProps) {
  useDocumentTitle(props.title)

  const [isLoading, setIsLoading] = useState(true)
  const [allUsers, setAllUsers] = useState<AppUser[]>([])
  const [filteredUsers, setFilteredUsers] = useState<AppUser[]>([])
  const [shownUsers, setShownUsers] = useState<AppUser[]>([])
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15
  const dispatch = useDispatch()
  const appUserService = new AppUserService(dispatch)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  useEffect(() => {
    _initialize()
  }, [])

  const _initialize = async () => {
    const res = await appUserService.listAllAppUsers()
    if (res.users) {
      setAllUsers(res.users)
      setFilteredUsers(res.users)
      setShownUsers(res.users.slice(0, itemsPerPage))
      setTotal(res.users.length)
      setIsLoading(false)
    }
  }

  const _isSameAsToday = (date: Date): boolean => {
    const today = new Date()
    // Compare year, month, and day of both dates
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    )
  }

  const _submitSearch = (e: FormEvent) => {
    e.preventDefault()

    const filtered = allUsers?.filter(u => u.id.includes(search))
    setFilteredUsers(filtered)
    setShownUsers(filtered.slice(0, itemsPerPage))
  }

  const _clearSearch = () => {
    setSearch('')
    setFilteredUsers(allUsers)
    setShownUsers(allUsers.slice(0, itemsPerPage))
  }

  const _openWordpress = (id: string) => {
    window.open(
      `https://carnivoretrading.com/wp-admin/user-edit.php?user_id=${id}`,
      '_blank'
    )
  }

  return (
    <Container className='p-3'>
      <h1 className='text-start'>Mobile Users</h1>
      <p className='text-start'>Gain Insight into all things mobile</p>

      {isLoading && <Spinner animation='border' />}
      {!isLoading && (
        <>
          <Row>
            <Col className='p-3'>
              <NumberCard variant='primary' label='Total Users' value={total} />
            </Col>
            <Col className='p-3'>
              <NumberCard
                variant='warning'
                label='New Today'
                value={
                  allUsers?.filter(user =>
                    _isSameAsToday(new Date(user.createdAt))
                  ).length
                }
              />
            </Col>
            <Col className='p-3'>
              <NumberCard
                variant='success'
                label='Active Today'
                value={
                  allUsers?.filter(user =>
                    _isSameAsToday(new Date(user.lastSignIn))
                  ).length
                }
              />
            </Col>
            <Col className='p-3'>
              <NumberCard
                variant='danger'
                label='Apple Users'
                value={
                  allUsers?.filter(user => user.deviceType === 'ios').length
                }
              />
            </Col>
            <Col className='p-3'>
              <NumberCard
                variant='info'
                label='Android Users'
                value={
                  allUsers?.filter(user => user.deviceType !== 'ios').length
                }
              />
            </Col>
          </Row>

          <Form onSubmit={_submitSearch}>
            <Row className='justify-content-center my-3'>
              <Col md={5}>
                <Form.Group className='mb-3'>
                  <Form.Control
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search Email'
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button variant='primary' type='submit'>
                  Search
                </Button>
                {search !== '' && (
                  <AiFillCloseCircle
                    onClick={_clearSearch}
                    className='pointer ms-2'
                    title='reset'
                  />
                )}
              </Col>
            </Row>
          </Form>

          <Table striped bordered hover responsive className=''>
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Notification</th>
                <th>Last Seen</th>
                <th>Platform</th>
                <th>Version</th>
                <th>On Active Carnivore</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map(u => (
                <tr key={u.id}>
                  <td>{u.id}</td>
                  <td>{u.name}</td>
                  <td>
                    {u.pushToken ? (
                      <AiFillCheckCircle className='text-success' />
                    ) : (
                      <AiFillCloseCircle className='text-danger' />
                    )}
                  </td>
                  <td>
                    {new Date(u.lastSignIn).toLocaleString('en-US', {
                      dateStyle: 'short',
                      timeStyle: 'short'
                    })}
                  </td>
                  <td>
                    {u.deviceType === 'ios' ? (
                      <AiFillApple className='text-secondary' />
                    ) : (
                      <AiFillAndroid className='text-success' />
                    )}
                  </td>
                  <td>{u.currentVersion}</td>
                  <td>
                    {u.klaviyoList?.includes('ViPscw') ? (
                      <AiFillCheckCircle className='text-success' />
                    ) : (
                      <AiFillCloseCircle className='text-danger' />
                    )}
                  </td>
                  <td className='align-middle'>
                    {u.wordpressId && (
                      <FaWordpress
                        title='open in wordpress'
                        className='pointer me-2 text-primary align-middle'
                        onClick={() => _openWordpress(u.wordpressId ?? '')}
                      />
                    )}
                    {u.klaviyoId && (
                      <span
                        className='pointer me-1 my-auto align-middle'
                        title='Open in Klaviyo'
                        style={{ fontWeight: 'bolder' }}
                        onClick={() =>
                          window.open(
                            `https://www.klaviyo.com/profile/${
                              u.klaviyoId ?? ''
                            }`,
                            '_blank'
                          )
                        }
                      >
                        K
                      </span>
                    )}
                    {/* <MdBlock
                      title='Block Mobile User'
                      className='pointer me-2 text-danger align-middle'
                      onClick={() => _openWordpress(u.wordpressId ?? '')}
                    /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='d-flex justify-content-center'>
            <Pagination>
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {Array.from({ length: totalPages }, (_, i) => (
                <Pagination.Item
                  key={i}
                  active={i + 1 === currentPage}
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </>
      )}
    </Container>
  )
}
