import { useState } from 'react'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { Button, Container } from 'react-bootstrap'
import PremiumContentModal from '../components/modal/premium-content-modal'

export default function PremiumContent (props: PageProps) {
  useDocumentTitle(props.title)

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  return (
    <Container className='p-3'>
      <div className='text-start'>
        <h1>Premium Content</h1>
        <p className='lead'>
          Create Content behind the paywall. Upload videos, notes, pds and more
        </p>

        <div className='text-end'>
          <Button variant='success' onClick={() => setShowModal(!showModal)}>
            Create Content
          </Button>
        </div>
      </div>
      <PremiumContentModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </Container>
  )
}
