import React, { useState, useEffect } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../_types/carnivore-state.type'
import Actions from '../redux/actions'

export default function StatusBar () {
  const [show, setShow] = useState(false)

  const statusMsg = useSelector((state: CarnivoreState) => state.statusMsg)
  const dispatch = useDispatch()
  useEffect(() => {
    if (statusMsg && statusMsg !== '') {
      setShow(true)
      const timer = setTimeout(() => {
        dispatch(Actions.setStatusBar(''))
      }, 5000)
    } else {
      setShow(false)
    }
  }, [statusMsg])

  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className={`top-bar bg-success ${show ? 'show' : ''}`}>
      {statusMsg}
      <AiFillCloseCircle
        className='text-white pointer close-button mt-1'
        onClick={handleClose}
      />
    </div>
  )
}
