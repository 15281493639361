import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import DragFileInput from '../../drag-file-input'
import LoadingBtn from '../../loading-btn'
import WrapDiscardBtn from '../wrap-discard-button'

export default function WrapIndex () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [loading, setLoading] = useState(false)
  const [spxIndexSrc, setSpxIndexSrc] = useState<string | null>(null)
  const [compqIndexSrc, setCompqIndexSrc] = useState<string | null>(null)

  const [content, setContent] = useState('')
  const [dowPoints, setDowPoints] = useState('')
  const [nasdaqPoints, setNasdaqPoints] = useState('')
  const [flicker, setFlicker] = useState(true)
  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)

  const _onSubmit = async () => {
    if (!nasdaqPoints || !dowPoints || !spxIndexSrc || !compqIndexSrc) {
      dispatch(
        Actions.setError(
          'DOW Points, NASDAQ Points, SPX Chart, and CompQ Charts are all required fields '
        )
      )
      return
    }

    try {
      Number(dowPoints)
      Number(nasdaqPoints)
    } catch (e) {
      dispatch(
        Actions.setError('DOW Points and NASDAQ Points must be a valid number')
      )
      return
    }

    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      dowPoints: Number(dowPoints),
      nasdaqPoints: Number(nasdaqPoints),
      $set: {
        'contentBlocks.indexBreakdown': content,
        'images.spxIndexSrc': spxIndexSrc,
        'images.compqIndexSrc': compqIndexSrc
      }
    })
    // console.log(resp)
    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
    } else {
      dispatch(Actions.setError('An Unknown error occurred'))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentWrap) {
      setContent(currentWrap?.contentBlocks?.indexBreakdown ?? '')
      setDowPoints(String(currentWrap?.dowPoints ?? ''))
      setNasdaqPoints(String(currentWrap?.nasdaqPoints ?? ''))
      setSpxIndexSrc(currentWrap.images?.spxIndexSrc ?? '')
      setCompqIndexSrc(currentWrap.images?.compqIndexSrc ?? '')
      setFlicker(false)
      setTimeout(() => {
        setFlicker(true)
      }, 200)
    }
  }, [currentWrap])

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Performance</h4>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />

      <Row className=' flex-wrap'>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>DOW Points</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  type='number'
                  placeholder='DOW'
                  value={dowPoints}
                  onChange={e => {
                    dispatch(Actions.setUnsavedChanged(true))
                    e.target.value
                      ? setDowPoints(e.target.value)
                      : setDowPoints('')
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className='p-3 mt-1 rounded border shadow text-start'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>NASDAQ Points</Form.Label>
              <InputGroup className='mb-3'>
                <Form.Control
                  type='number'
                  placeholder='NASDAQ'
                  value={nasdaqPoints}
                  onChange={e => {
                    dispatch(Actions.setUnsavedChanged(true))
                    e.target.value
                      ? setNasdaqPoints(e.target.value)
                      : setNasdaqPoints('')
                  }}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={spxIndexSrc ?? ''}
                imgType='spxIndexSrc'
                label='SPX Chart'
                onFileUploaded={imgSrc => setSpxIndexSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={compqIndexSrc ?? ''}
                imgType='compqIndexSrc'
                label='COMPQ Chart'
                onFileUploaded={imgSrc => setCompqIndexSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
      </Row>
      <div className='text-start'>
        <Form.Label>Index Breakdown</Form.Label>
      </div>
      {flicker && currentWrap && (
        <RichTextEditor
          initialValue={currentWrap?.contentBlocks?.indexBreakdown ?? ''}
          onHTMLChange={e => {
           
            setContent(e)
          }}
        />
      )}
    </Container>
  )
}
