import { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import LoadingBtn from '../../loading-btn'

export default function WrapBonus () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const [flicker, setFlicker] = useState(true)
  const [copyLoading, setCopyLoading] = useState(false)

  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)
  const _onSubmit = async () => {
    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      $set: {
        'contentBlocks.bonus': content
      }
    })
    console.log(resp)
    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
      console.log('setting toast')
    } else {
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentWrap) {
      setContent(currentWrap?.contentBlocks?.bonus ?? '')
      setFlicker(false)
      setTimeout(() => {
        setFlicker(true)
      }, 200)
    }
  }, [currentWrap])

  const _copyFromYesterday = async () => {
    setCopyLoading(true)
    const resp = await wrapService.getPreviousWrap(QueryHelper.get('id') ?? '')
    if (resp.wrap) {
      setContent(resp.wrap.contentBlocks?.bonus ?? '')
      const response = await wrapService.updateWrap(currentWrap?.id ?? '', {
        $set: {
          'contentBlocks.bonus': resp.wrap.contentBlocks?.bonus ?? ''
        }
      })
      if (response.wrap) {
        dispatch(Actions.setCurrentWrap(response.wrap))
        dispatch(
          Actions.setToast(
            'Copied and saved from yesterday ✅',
            'Your changes have been saved'
          )
        )
      }
    }
    setCopyLoading(false)
  }

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Bonus Section</h4>
          <p className='text-start'>
            (Optionally) Include anything you want here that doesn't fit in any
            where else
          </p>
        </Col>
        <Col className='text-end'>
          <LoadingBtn
            className='mx-2'
            disabled={copyLoading}
            isLoading={copyLoading}
            onClick={_copyFromYesterday}
            text='Copy From Last'
            variant='secondary'
            type='button'
          />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />
      {flicker && (
        <RichTextEditor
          initialValue={currentWrap?.contentBlocks?.bonus ?? ''}
          onHTMLChange={e => {
            
            setContent(e)
          }}
        />
      )}
    </Container>
  )
}
