import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin/console-user`,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class ConsoleUserService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async login (email: string, password: string) {
    return axiosInstance
      .post('/login', {
        email: email.toLowerCase(),
        password
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async changePassword (oldPassword: string, newPassword: string) {
    return axiosInstance
      .post('/change-password', {
        oldPassword,
        newPassword
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async createUser (email: string, name: string) {
    return axiosInstance
      .post('/', {
        email: email.toLowerCase(),
        name,
        role: 'admin'
      })
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async listUsers () {
    return axiosInstance
      .get('/list')
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }

  async getConsoleUser () {
    return axiosInstance
      .get('/')
      .then(res => res.data.data)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
