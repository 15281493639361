const ActionTypes = {
  SET_USER: 'SET_USER',
  SET_ERROR: 'SET_ERROR',
  SET_UNSAVED_CHANGES: 'SET_UNSAVED_CHANGES',
  SET_TOAST: 'SET_TOAST',
  CLEAR_TOAST: 'CLEAR_TOAST',
  SET_STATUS_BAR: 'SET_STATUS_BAR',
  SET_CURRENT_WRAP: 'SET_CURRENT_WRAP',
  SET_BUILDER_PAGE: 'SET_BUILDER_PAGE',
  SET_CURRENT_CONTENT: 'SET_CURRENT_CONTENT'

}

export default ActionTypes
