import { WrapBuilderPage } from '../_constants/enums/wrap-builder-pages.enum'
import { CarnivoreContent } from '../_types/carnivore-content.type'
import { ConsoleUser } from '../_types/console-user.type'
import { DailyWrap } from '../_types/daily-wrap.type'
import ActionTypes from './action-types'
const Actions = {
  setUser: (user: ConsoleUser) => ({
    type: ActionTypes.SET_USER,
    payload: {
      user
    }
  }),
  setError: (errMsg: string) => ({
    type: ActionTypes.SET_ERROR,
    payload: {
      errMsg
    }
  }),

  setCurrentContent: (content: CarnivoreContent | null) => ({
    type: ActionTypes.SET_CURRENT_CONTENT,
    payload: {
      content
    }
  }),

  setStatusBar: (statusMsg: string) => ({
    type: ActionTypes.SET_STATUS_BAR,
    payload: {
      statusMsg
    }
  }),

  setCurrentWrap: (wrap: DailyWrap | null) => ({
    type: ActionTypes.SET_CURRENT_WRAP,
    payload: {
      wrap
    }
  }),
  setUnsavedChanged: (unsavedChanges: boolean) => ({
    type: ActionTypes.SET_UNSAVED_CHANGES,
    payload: {
      unsavedChanges
    }
  }),
  setBuilderPage: (wrapBuilderPage: WrapBuilderPage) => ({
    type: ActionTypes.SET_BUILDER_PAGE,
    payload: {
      wrapBuilderPage
    }
  }),
  setToast: (toastHeader: string, toastMsg: string) => ({
    type: ActionTypes.SET_TOAST,
    payload: {
      toastHeader,
      toastMsg
    }
  }),
  clearToast: () => ({
    type: ActionTypes.CLEAR_TOAST,
    payload: {}
  })
}
export default Actions
