import { FormEvent, useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { AiFillEye, AiFillEyeInvisible, AiFillMail } from 'react-icons/ai'
import LoadingBtn from '../components/loading-btn'
import { useDispatch } from 'react-redux'
import Actions from '../redux/actions'
import { ConsoleUserService } from '../services/consoleuser.service'
import { PageProps } from '../_types/page.props.interface'
import useDocumentTitle from '../helpers/title.helper'
import { useNavigate } from 'react-router-dom'
import { QueryHelper } from '../helpers/queryparams.helper'

export default function Login (props: PageProps) {
  useDocumentTitle(props.title)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPw, setShowPw] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const consoleUserService = new ConsoleUserService(dispatch)
  const navigate = useNavigate()

  useEffect(() => {
    const expiredFlag = QueryHelper.get('expired')
    if (expiredFlag === 'true') {
      dispatch(Actions.setError(`Your Session Has Expired`))
    }
  }, [])
  const _login = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const resp = await consoleUserService.login(email, password)
    console.log(resp)
    if (resp.token) {
      await consoleUserService.getConsoleUser()
      localStorage.setItem('carnivore-token', resp.token)
      dispatch(Actions.setUser(resp.user))
      navigate('/', { replace: true })
    }
    setIsLoading(false)
  }
  return (
    <Row className='vh-100 p-0'>
      <Col
        md={5}
        className='text-center d-flex flex-column align-items-center justify-content-center bg-light p-4'
      >
        <h2 className='mt-3 display-5'>
          Welcome to the New and Improved{' '}
          <strong className='text-primary'>Carnivore Admin Hub</strong>
        </h2>
      </Col>
      <Col
        md={7}
        className='text-center px-3 d-flex flex-column align-items-center justify-content-center bg-dark'
      >
        <Row className='w-100'>
          <Col xs={8} className='mx-auto'>
            <div className='bg-light rounded border shadow p-3 '>
              <Row>
                <Col md={2} className='mx-auto'>
                  <img
                    src={require('../assets/logo.png')}
                    alt='carnivore-logo'
                    className='img-fluid'
                  />
                </Col>
              </Row>
              <h4 className='my-3'>Login to Get Started</h4>
              <Form onSubmit={_login}>
                <Form.Group
                  className='mb-3 text-start'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label className='text-start'>Email address</Form.Label>
                  <InputGroup className='mb-3'>
                    <Form.Control
                      required
                      type={'email'}
                      placeholder='your@email.com'
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <InputGroup.Text className='bg-dark rounded-end'>
                      <AiFillMail className=' text-white' />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className='mt-3 text-start'>
                  <Form.Label className='text-start'>Password</Form.Label>

                  <InputGroup className='mb-3'>
                    <Form.Control
                      required
                      type={!showPw ? 'password' : 'text'}
                      placeholder='Password'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <InputGroup.Text className='bg-dark rounded-end'>
                      {showPw ? (
                        <AiFillEye
                          className='pointer text-white'
                          onClick={() => setShowPw(!showPw)}
                        />
                      ) : (
                        <AiFillEyeInvisible
                          className='pointer text-white'
                          onClick={() => setShowPw(!showPw)}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <LoadingBtn
                  type='submit'
                  variant='primary'
                  className='px-5'
                  disabled={!email || !password}
                  isLoading={isLoading}
                  text='Login'
                />
              </Form>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
