import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/user`,
    withCredentials: true
})

axiosInstance.interceptors.request.use(
    async req => {
        // Add configurations here
        req.headers['Authorization'] =
            'Bearer: ' + localStorage.getItem('carnivore-token')
        return req
    },
    err => {
        return Promise.reject(err)
    }
)

export class UserService {
    private dispatch: Dispatch

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }

    async queryUsers(
        page: number,
        sortKey?: string,
        sortOrder?: string,
        search?: string
    ) {
        return axiosInstance
            .get('/list', {
                params: {
                    page,
                    sortKey,
                    sortOrder,
                    search
                }
            })
            .then(res => res?.data?.data)
            .catch(e => apiErrorHandler(e, this.dispatch))
    }
}
