import { Button, ListGroup } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { WrapBuilderPage } from '../../_constants/enums/wrap-builder-pages.enum'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { QueryHelper } from '../../helpers/queryparams.helper'
import Actions from '../../redux/actions'
import { useSearchParams } from 'react-router-dom'

interface WrapNavItemProps {
  label: string
  pageKey: WrapBuilderPage
  disabled: boolean
  complete: boolean
}

export default function WrapNavItem (props: WrapNavItemProps) {
  const currentPage = useSelector(
    (state: CarnivoreState) => state.wrapBuilderPage
  )

  let [searchParams, setSearchParams] = useSearchParams()
  const unsavedChanges = useSelector(
    (state: CarnivoreState) => state.unsavedChanges
  )
  const dispatch = useDispatch()

  return (
    <ListGroup.Item
      className={`${currentPage === props.pageKey ? 'bg-light' : ''}`}
    >
      <Button
        disabled={props.disabled}
        variant='link p-0'
        onClick={() => {
          if (unsavedChanges) {
            alert(
              'You have unsaved changes that either need to be saved or discarded'
            )
          } else {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.set('tab', props.pageKey)
            setSearchParams(newSearchParams)

            dispatch(Actions.setBuilderPage(props.pageKey))
          }
        }}
      >
        {props.label}
        {props.complete && <FaCheck className='ms-2 text-success' />}
      </Button>
    </ListGroup.Item>
  )
}
