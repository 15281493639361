import axios from 'axios'
import { Dispatch } from 'redux'
import apiErrorHandler from '../helpers/apiErrorHandler'

const HEADERS = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/alert`,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  async req => {
    // Add configurations here
    req.headers['Authorization'] =
      'Bearer: ' + localStorage.getItem('carnivore-token')
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

export class AlertService {
  private dispatch: Dispatch

  constructor (dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  async searchAlerts (
    page: number,
    ticker?: string,
    alertType?: string,
    limit?: number
  ) {
    return axiosInstance
      .get('/search', {
        params: {
          ticker,
          alertType,
          page,
          limit
        }
      })
      .then(res => res?.data?.data?.alerts)
      .catch(e => apiErrorHandler(e, this.dispatch))
  }
}
