import { useSearchParams } from 'react-router-dom'

type SetQueryParams<T> = (params: Partial<T>) => void

function useQueryParamsV2<T extends Record<string, any>> (): [
  T,
  SetQueryParams<T>
] {
  const [searchParams, setSearchParams] = useSearchParams()

  // Function to get all query params and cast them to the generic type T
  const getQueryParams = (): T => {
    const params: any = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    return params as T
  }

  // Function to set one or more query params
  const setQueryParams: SetQueryParams<T> = newParams => {
    const currentParams = new URLSearchParams(searchParams)
    // Update or delete specified parameters
    Object.entries(newParams).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        currentParams.delete(key)
      } else {
        currentParams.set(key, value.toString())
      }
    })
    // Replace the current search parameters without navigating
    setSearchParams(currentParams, { replace: true })
  }

  return [getQueryParams(), setQueryParams]
}

export default useQueryParamsV2
