import { useEffect, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
import { QueryHelper } from '../../helpers/queryparams.helper'
import { useDispatch } from 'react-redux'
import Actions from '../../redux/actions'
import { WrapService } from '../../services/wrap.service'
import {
  Accordion,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs
} from 'react-bootstrap'
import { PageProps } from '../../_types/page.props.interface'
import useDocumentTitle from '../../helpers/title.helper'
import FullScreenImg from '../../components/fullscreen-img'
import { FaFire } from 'react-icons/fa'

const PLACE_HOLD_IMG = 'https://placehold.co/600x400'

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})
export default function DailyWrapPreview (props: PageProps) {
  useDocumentTitle(props.title)
  const [wrap, setWrap] = useState<DailyWrap | null>()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)

  useEffect(() => {
    _loadWrap()
  }, [])
  const _loadWrap = async () => {
    const id = QueryHelper.get('id')
    if (!id) {
      setLoading(false)
      dispatch(Actions.setError('No ID Parameter'))
      return
    }

    const resp = await wrapService.getById(id)
    if (!resp.wrap) {
      dispatch(Actions.setError('Could not find wrap with id' + id))
    } else {
      setWrap(resp.wrap)
    }
    setLoading(false)
  }

  return (
    <Container className='p-3 px-5'>
      {loading && <Spinner animation='border' />}
      {wrap && (
        <>
          <h1 className='text-primary'>
            WRAP PREVIEW FOR {wrap?.formattedDate} {wrap?.tag}
          </h1>
          <p>
            <small>
              <em>NOTE: this page is not 1-1 with the published version. </em>
            </small>
          </p>
          <hr className='mb-5' />
          <div className='px-5 text-center wrap-preview bg-light'>
            <h2 className='display-5'>
              <strong>Current YTD Returns {wrap.currentYTD ?? ''}%</strong>
            </h2>
            <p className='lead'>
              Welcome to our Daily Wrap. Carnivore is an old school trading desk
              operation. We are not politically correct. We don't take in
              people's money. We only manage our own. We're not trying to sell
              you anything. Except maybe a path to your own True Freedom. With
              our model portfolio below, you can see what we are all about.
            </p>
            <FullScreenImg
              imgSrc={wrap.images?.performanceSrc ?? PLACE_HOLD_IMG}
            />

            <p className='mt-4'>
              <a
                href='https://carnivoretrading.com/performance/'
                target='_blank'
                rel='noreferrer'
              >
                View our full performance page
              </a>
            </p>
            <h2 className='mt-3 font-weight-bold'>Market Overview</h2>
            <h3 className='font-weight-bold'>Market Headlines</h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  wrap.contentBlocks?.marketHeadlines ??
                  '<p>NOT YET CREATED</p>'
              }}
            />

            <h3 className='font-weight-bold mt-5'>Index Performance</h3>

            <Row className='bg-light rounded'>
              <Col className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <Row>
                    <Col md={6} className='mx-auto'>
                      <img
                        alt='sp'
                        src='https://media.carnivoretrading.com/wp-content/uploads/2023/10/3.png'
                        className='img-fluid'
                      />
                    </Col>
                  </Row>

                  <h4 className='mt-3'>{wrap.dowPoints}</h4>
                  <p>Points Today</p>
                  <FullScreenImg
                    imgSrc={wrap.images?.spxIndexSrc ?? PLACE_HOLD_IMG}
                  />
                </div>
              </Col>
              <Col className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <Row>
                    <Col md={6} className='mx-auto'>
                      <img
                        alt='nas'
                        src='https://media.carnivoretrading.com/wp-content/uploads/2023/10/2.png'
                        className='img-fluid'
                      />
                    </Col>
                  </Row>

                  <h4 className='mt-3'>{wrap.nasdaqPoints}</h4>
                  <p>Points Today</p>
                  <FullScreenImg
                    imgSrc={wrap.images?.compqIndexSrc ?? PLACE_HOLD_IMG}
                  />
                </div>
              </Col>

              <Accordion className='m-3'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    Read more about our index breakdown
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          wrap.contentBlocks?.indexBreakdown ??
                          '<p>Not set yet</p>'
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>

            <h3 className='font-weight-bold mt-5'>Sector Performance</h3>
            <Row className='flex-wrap bg-light rounded justify-content-center'>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Technology</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.technology ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Energy</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.energy ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Industrials</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.industrials ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Materials</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.materials ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Real Estate</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.realEstate ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Health Care</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.healthCare ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Financial</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.financial ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Communication</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.communication ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Utilities</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.utilities ?? 'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Consumer Discretionary</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.consumerDiscretionary ??
                      'no commentary today'}
                  </p>
                </div>
              </Col>
              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Consumer Staples</h4>
                  <p className='mt-3'>
                    {wrap.sectors?.consumerStaples ?? 'no commentary today'}
                  </p>
                </div>
              </Col>

              <Col md={4} className='p-3'>
                <div className='bg-white p-4 border rounded'>
                  <h4 className='font-weight-bold'>Heat Map</h4>
                  <FullScreenImg
                    imgSrc={wrap.images?.heatMapSrc ?? PLACE_HOLD_IMG}
                  />
                </div>
              </Col>

              <Accordion className='m-3'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    Read more about our sector breakdown
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          wrap.contentBlocks?.sectorBreakdown ??
                          '<p>Not set yet</p>'
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>

            <h2 className='font-weight-bold mt-5'>Portfolio Overview</h2>

            <h3 className='font-weight-bold mt-5 mb-4'>Swing Trades </h3>
            <FullScreenImg
              imgSrc={wrap.images?.modelPortfolioSwingSrc ?? PLACE_HOLD_IMG}
            />
            <h3 className='font-weight-bold mt-5 mb-4'>
              Portfolio Performance{' '}
            </h3>
            <FullScreenImg
              imgSrc={wrap.images?.portfolioPerformanceSrc ?? PLACE_HOLD_IMG}
            />

            <h3 className='font-weight-bold mt-5 mb-4'>Percent of Holding</h3>
            <FullScreenImg
              imgSrc={wrap.images?.percentPortfolioSrc ?? PLACE_HOLD_IMG}
            />

            <h3 className='font-weight-bold mt-5 mb-4'>Specialty Trades</h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  wrap.contentBlocks?.specialtyTrades ?? '<p>None today</p>'
              }}
            />

            <h3 className='font-weight-bold mt-5 mb-4'>Positions</h3>
            <Tabs defaultActiveKey={'0'} className=' bg-white'>
              {wrap.positions?.map((p, i) => (
                <Tab
                  eventKey={`${i}`}
                  title={p.ticker}
                  className='p-4 bg-white'
                >
                  <Row>
                    <Col className='text-start'>
                      <h2 className='font-weight-bold '>{p.ticker}</h2>
                      <h5>
                        <strong>Current Shares:</strong>{' '}
                        {Number(p.shares ?? 0).toLocaleString('en-US')}
                      </h5>
                      {p.newPosition && (
                        <p className='text-orange font-weight-bold'>
                          <FaFire className='me-1' />
                          New Position{' '}
                        </p>
                      )}
                      <p className='lead'>{p.commentary ?? 'no commentary'}</p>
                      <Form.Label>About</Form.Label>
                      <p>{p.descr ?? 'no commentary'}</p>
                    </Col>
                    <Col>
                      <FullScreenImg imgSrc={p.chartSrc ?? PLACE_HOLD_IMG} />
                    </Col>
                  </Row>
                </Tab>
              ))}
            </Tabs>

            <h2 className='font-weight-bold mt-5'>Trading Day in Review</h2>
            <h3 className='font-weight-bold mt-5 mb-4'>Profits + Losses</h3>

            <div className='bg-white p-3 rounded border'>
              <Row>
                <Col>
                  <h4>Sold for Profit</h4>
                  <p className='mt-4 font-weight-bold'>
                    {moneyFormatter.format(wrap.profitSold ?? 0)}
                  </p>
                </Col>
                <Col>
                  <h4>Sold for Profit</h4>
                  <p className='mt-4 font-weight-bold'>
                    {moneyFormatter.format(wrap.lossSold ?? 0)}
                  </p>
                </Col>
              </Row>

              <p className='lead mt-4'>
                We started the day with a value of{' '}
                <strong>{moneyFormatter.format(wrap.startValue ?? 0)}</strong>{' '}
                and we ended the day at{' '}
                <strong>{moneyFormatter.format(wrap.endValue ?? 0)}</strong>
              </p>
            </div>

            <h3 className='font-weight-bold mt-5 mb-4'>Today's Trade Report</h3>
            <div className='bg-white p-3'>
              <h2 className='font-weight-bold mt-5'>Trading Day in Summary</h2>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    wrap.contentBlocks?.tradingSummary ?? '<p>None today</p>'
                }}
              />

              <h3 className='font-weight-bold mt-5 mb-4'>New Positions</h3>

              {wrap.positions
                ?.filter(p => p.newPosition === true)
                ?.map((p, i) => (
                  <div
                    className='bg-white border rounded shadow m-3 p-4'
                    key={`new-${i}`}
                  >
                    <Row>
                      <Col className='text-start'>
                        <h1>{p.ticker ?? ''}</h1>
                      </Col>
                      <Col className='text-end'>
                        <h2 className='text-orange'>
                          <FaFire className='me-1' />
                          New Carnivore Position
                        </h2>
                      </Col>
                    </Row>
                    <p>{p.descr}</p>
                  </div>
                ))}
            </div>

            <h3 className='font-weight-bold mt-5 mb-4'>
              AM SESSION | PM SESSION
            </h3>
            <div className='bg-white p-3 m-2'>
              <h2 className='font-weight-bold mt-5'>AM Session</h2>
              <hr className='my-5' />
              <div
                dangerouslySetInnerHTML={{
                  __html: wrap.contentBlocks?.amSession ?? '<p>None today</p>'
                }}
              />
            </div>
            <div className='bg-white p-3 m-2'>
              <h2 className='font-weight-bold mt-5'>PM Session</h2>
              <hr className='my-5' />
              <div
                dangerouslySetInnerHTML={{
                  __html: wrap.contentBlocks?.pmSession ?? '<p>None today</p>'
                }}
              />
            </div>

            <h3 className='font-weight-bold mt-5 mb-4'>Trader Z's Thoughts</h3>
            <div className='bg-white p-3 m-2 rounded shadow'>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    wrap.contentBlocks?.traderZThoughts ?? '<p>None today</p>'
                }}
              />
            </div>

            <h3 className='font-weight-bold mt-5 mb-4'>
              Thoughts from the Trading Desk with Dutch
            </h3>
            <div className='bg-white p-3 m-2 rounded shadow'>
              <div
                dangerouslySetInnerHTML={{
                  __html: wrap.contentBlocks?.commentary ?? '<p>None today</p>'
                }}
              />
            </div>

            <div className='bg-white p-3 m-2 rounded shadow'>
              <div
                dangerouslySetInnerHTML={{
                  __html: wrap.contentBlocks?.bonus ?? ''
                }}
              />
            </div>
            <h3 className='font-weight-bold mt-5 mb-4'>Carnivore Babes</h3>
            <div className='bg-white p-3 m-2 rounded shadow'>
              <div
                dangerouslySetInnerHTML={{
                  __html: wrap.contentBlocks?.babes ?? '<p>None today</p>'
                }}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
