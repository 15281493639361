import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import RichTextEditor from '../../rich-text-editor'
import { useDispatch, useSelector } from 'react-redux'
import { WrapService } from '../../../services/wrap.service'
import { QueryHelper } from '../../../helpers/queryparams.helper'
import Actions from '../../../redux/actions'
import { CarnivoreState } from '../../../_types/carnivore-state.type'
import DragFileInput from '../../drag-file-input'
import LoadingBtn from '../../loading-btn'
import WrapDiscardBtn from '../wrap-discard-button'

export default function WrapPortfolio () {
  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const [loading, setLoading] = useState(false)
  const [percentPortfolioSrc, setPercentPortfolioSrc] = useState<string | null>(
    null
  )
  const [portfolioPerformanceSrc, setPortfolioPerformanceSrc] = useState<
    string | null
  >(null)

  const [modelPortfolioSwingSrc, setModelPortfolioSwingSrc] = useState<
    string | null
  >(null)

  const [flicker, setFlicker] = useState(true)
  const dispatch = useDispatch()

  const wrapService = new WrapService(dispatch)

  const _onSubmit = async () => {
    setLoading(true)
    const resp = await wrapService.updateWrap(QueryHelper.get('id') ?? '', {
      $set: {
        'images.percentPortfolioSrc': percentPortfolioSrc,
        'images.modelPortfolioSwingSrc': modelPortfolioSwingSrc,
        'images.portfolioPerformanceSrc': portfolioPerformanceSrc
      }
    })
    // console.log(resp)
    if (resp.wrap) {
      dispatch(
        Actions.setToast('Changes Saved ✅', 'Your changes have been saved')
      )
      dispatch(Actions.setCurrentWrap(resp.wrap))
    } else {
      dispatch(Actions.setError('An Unknown error occurred'))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentWrap) {
      setPercentPortfolioSrc(currentWrap.images?.percentPortfolioSrc ?? '')
      setPortfolioPerformanceSrc(
        currentWrap.images?.portfolioPerformanceSrc ?? ''
      )
      setModelPortfolioSwingSrc(
        currentWrap.images?.modelPortfolioSwingSrc ?? ''
      )
      setFlicker(false)
      setTimeout(() => {
        setFlicker(true)
      }, 200)
    }
  }, [currentWrap])

  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h4 className='text-start'>Performance</h4>
        </Col>
        <Col className='text-end'>
          <WrapDiscardBtn />
          <LoadingBtn
            disabled={loading}
            isLoading={loading}
            onClick={_onSubmit}
            text='Save'
            variant='primary'
            type='button'
          />
        </Col>
      </Row>
      <hr />

      <Row className=' flex-wrap'>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={modelPortfolioSwingSrc ?? ''}
                imgType='modelPortfolioSwingSrc'
                label='Swing Trades Table'
                onFileUploaded={imgSrc => setModelPortfolioSwingSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={portfolioPerformanceSrc ?? ''}
                imgType='portfolioPerformanceSrc'
                label='All Positions'
                onFileUploaded={imgSrc => setPortfolioPerformanceSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
        <Col md={4} className='p-3'>
          <div className='p-3 rounded border shadow text-start h-100'>
            {flicker && (
              <DragFileInput
                initialSrc={percentPortfolioSrc ?? ''}
                imgType='percentPortfolioSrc'
                label='Percent Donut chart'
                onFileUploaded={imgSrc => setPercentPortfolioSrc(imgSrc)}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
