import { FormEvent, useState } from 'react'
import { Modal, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import PAIModalProps from '../../_types/modal-props.type'
import CarnivoreModalProps from '../../_types/modal-props.type'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import LoadingBtn from '../loading-btn'
import { ConsoleUserRole } from '../../_constants/enums/console-user-role.enum'
import { ConsoleUserService } from '../../services/consoleuser.service'

interface CreateUserModalProps extends CarnivoreModalProps {
 
}

export default function CreateUserModal (props: CreateUserModalProps) {
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(ConsoleUserRole.admin)

  const [tempPass, setTempPass] = useState('')
  const error = useSelector((state: CarnivoreState) => state.errMsg)

  const dispatch = useDispatch()
  const consoleUserService = new ConsoleUserService(dispatch)

  const _onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const resp = await consoleUserService.createUser(email, name)
    if (resp.password) {
      setTempPass(resp.password)
    }
    setLoading(false)
  }

  return (
    <Modal show={props.show} size='lg' centered onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New User</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant='danger my-3'>{error}</Alert>}

        {!tempPass && (
          <Form onSubmit={_onSubmit}>
            <Form.Group
              className='mb-3 text-start'
              controlId='exampleForm.ControlInput1'
            >
              <Form.Label className='text-start'>Email address</Form.Label>
              <Form.Control
                required
                type={'email'}
                placeholder='user@email.com'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className='mb-3 text-start'
              controlId='exampleForm.ControlInput1'
            >
              <Form.Label className='text-start'>Name</Form.Label>
              <Form.Control
                required
                type={'text'}
                placeholder='name'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>

            <div className='text-center'>
              <LoadingBtn
                text='Submit'
                variant='primary'
                disabled={loading}
                isLoading={loading}
                type='submit'
              />
            </div>
          </Form>
        )}
        {tempPass && (
          <>
            <Alert variant='success'>
              New User Created for <strong>{email}</strong>
            </Alert>
            <p>
              <strong>Important: </strong> Copy the password below and send it
              to {email}
            </p>
            <p>
              This password will <strong>NOT</strong> be shown again
            </p>

            <Alert variant='info m-3'>
              <strong>TEMP PASSWORD: </strong> {tempPass}
            </Alert>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='link' onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
