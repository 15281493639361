import {
  Button,
  Col,
  Container,
  ListGroup,
  ProgressBar,
  Row
} from 'react-bootstrap'
import { PageProps } from '../../_types/page.props.interface'
import useDocumentTitle from '../../helpers/title.helper'
import { FaMotorcycle, FaHatWizard } from 'react-icons/fa'
import RickTextEditor from '../../components/rich-text-editor'
import { useNavigate } from 'react-router-dom'
import WrapNav from '../../components/wrap/wrap-nav'
import { useDispatch, useSelector } from 'react-redux'
import { CarnivoreState } from '../../_types/carnivore-state.type'
import { WrapBuilderPage } from '../../_constants/enums/wrap-builder-pages.enum'
import WrapMetadata from '../../components/wrap/builder/wrap-metadata'
import { QueryHelper } from '../../helpers/queryparams.helper'
import { useEffect, useState } from 'react'
import { WrapService } from '../../services/wrap.service'
import Actions from '../../redux/actions'
import WrapTraderZ from '../../components/wrap/builder/wrap-traderz'
import WrapPerformance from '../../components/wrap/builder/wrap-performance'
import WrapHeadlines from '../../components/wrap/builder/wrap-headlines'
import WrapIndex from '../../components/wrap/builder/wrap-index'
import WrapSectors from '../../components/wrap/builder/wrap-sectors'
import WrapTradingSummary from '../../components/wrap/builder/wrap-tradingsummary'
import WrapAMSession from '../../components/wrap/builder/wrap-amsession'
import WrapPMSession from '../../components/wrap/builder/wrap-pmsession'
import WrapCommentary from '../../components/wrap/builder/wrap-commentary'
import WrapPortfolio from '../../components/wrap/builder/wrap-portfolio'
import WrapSpecialty from '../../components/wrap/builder/wrap-specialty'
import WrapPositions from '../../components/wrap/builder/wrap-positions'
import WrapBonus from '../../components/wrap/builder/wrap-bonus'
import WrapBabes from '../../components/wrap/builder/wrap.babes'
import WrapProgress from '../../components/wrap/wrap-progress'
import WrapPublish from '../../components/wrap/builder/wrap-publish'

export default function DailyWrapBuilder (props: PageProps) {
  useDocumentTitle(props.title)

  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const wrapService = new WrapService(dispatch)

  const currentBuilderPage = useSelector(
    (state: CarnivoreState) => state.wrapBuilderPage
  )

  const currentWrap = useSelector((state: CarnivoreState) => state.currentWrap)

  const navigate = useNavigate()

  useEffect(() => {
    _checkWrap()
  }, [])

  const _checkWrap = async () => {
    const id = QueryHelper.get('id')
    if (id) {
      const response = await wrapService.getById(id)
      if (response.wrap) {
        dispatch(Actions.setCurrentWrap(response.wrap))
      }
    }
    const tab = QueryHelper.get('tab')
    if (tab) {
      const enumMap: { [key: string]: WrapBuilderPage } = {}
      Object.keys(WrapBuilderPage).forEach(key => {
        enumMap[WrapBuilderPage[key as keyof typeof WrapBuilderPage]] =
          key as WrapBuilderPage
      })
      dispatch(Actions.setBuilderPage(enumMap[tab]))
    }

    setLoading(false)
  }
  return (
    <Container className='p-3'>
      <Row>
        <Col>
          <h1 className='text-start'>Daily Wrap</h1>
          <p className='text-start'>Edit, View, and Create New Daily Wraps</p>
        </Col>
        <Col className='my-auto'>
          {currentWrap?.id !== undefined && (
            <div className='text-end'>
              <Button
                variant='warning'
                onClick={() =>
                  window.open(
                    `/index.html#/daily-wrap/preview?id=${currentWrap?.id}`,
                    '_blank'
                  )
                }
              >
                Preview{' '}
              </Button>
            </div>
          )}
          <p className='text-end m-0 p-0 '>
            {currentWrap?.tag} Wrap for &nbsp;
            <em>
              {currentWrap?.formattedDate}
              {/* {new Date(currentWrap?.formattedDate ?? '').toLocaleDateString('en-US', {
                dateStyle: 'full'
              })} */}
            </em>
          </p>
        </Col>
      </Row>
      <WrapProgress />
      <Row>
        <Col md={2} className='text-start'>
          <WrapNav />
        </Col>
        <Col md={10}>
          {currentBuilderPage === WrapBuilderPage.meta && <WrapMetadata />}
          {currentBuilderPage === WrapBuilderPage.traderZ && <WrapTraderZ />}
          {currentBuilderPage === WrapBuilderPage.performance && (
            <WrapPerformance />
          )}
          {currentBuilderPage === WrapBuilderPage.headlines && (
            <WrapHeadlines />
          )}
          {currentBuilderPage === WrapBuilderPage.index && <WrapIndex />}
          {currentBuilderPage === WrapBuilderPage.sectors && <WrapSectors />}
          {currentBuilderPage === WrapBuilderPage.summary && (
            <WrapTradingSummary />
          )}
          {currentBuilderPage === WrapBuilderPage.amSession && (
            <WrapAMSession />
          )}
          {currentBuilderPage === WrapBuilderPage.pmSession && (
            <WrapPMSession />
          )}
          {currentBuilderPage === WrapBuilderPage.commentary && (
            <WrapCommentary />
          )}
          {currentBuilderPage === WrapBuilderPage.portfolio && (
            <WrapPortfolio />
          )}
          {currentBuilderPage === WrapBuilderPage.specialtyTrades && (
            <WrapSpecialty />
          )}
          {currentBuilderPage === WrapBuilderPage.bonus && <WrapBonus />}
          {currentBuilderPage === WrapBuilderPage.babes && <WrapBabes />}
          {currentBuilderPage === WrapBuilderPage.positions && (
            <WrapPositions />
          )}

          {currentBuilderPage === WrapBuilderPage.publish && <WrapPublish />}

          {/* <div className='text-end my-2'>
              <Button variant='primary'>Save</Button>
            </div>
            <RickTextEditor /> */}
        </Col>
      </Row>
      {/* <Row>
          <Col>
            <div className='border border-danger rounded shadow bg-white pointer p-3'>
              <FaMotorcycle size='6rem' className='text-danger mb-2' />
              <h2>Dutch</h2>
            </div>
          </Col>
          <Col>
            <div className='border border-primary rounded shadow bg-white p-3'>
              <FaHatWizard size='6rem' className='text-primary mb-2' />
  
              <h2>Trader Z</h2>
            </div>
          </Col>
        </Row> */}
    </Container>
  )
}
