class _QueryParamHelper {
  updateQueryParam = (key: string, param: string) => {
    const hashFragment = window.location.hash.substring(1)

    // Separate the path from the query string
    const [path, queryString] = hashFragment.split('?')
    const queryParams = new URLSearchParams(queryString)
    queryParams.set(key, param)

    if (window.history.pushState) {
      window.history.pushState(
        { path: `#/${path}?${queryParams.toString()}` },
        '',
        `#/${path}?${queryParams.toString()}`
      )
    }
  }

  get = (key: string): string | null => {
    
    const hashFragment = window.location.hash
    const queryString = hashFragment.substring(hashFragment.indexOf('?') + 1)

    const queryParams = new URLSearchParams(queryString)
    return queryParams.get(key)
  }
}

const helper = new _QueryParamHelper()
Object.freeze(helper)
export { helper as QueryHelper }
